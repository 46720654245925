@import "styles/scss-variables";
@import "antd/dist/antd.css";

//

.ant-picker-time-panel-column
	> li.ant-picker-time-panel-cell-selected
	.ant-picker-time-panel-cell-inner {
	background: #fdf4fd;
}

.ant-picker-now-btn {
	color: #f94e2f;
}

.ant-picker-now-btn:hover {
	color: #f3816d;
}

.ant-picker-cell-in-view.ant-picker-cell-selected {
	.ant-picker-cell-inner {
		background: #f94e2f;
	}

	.ant-picker-cell-inner::before {
		border-color: #f94e2f;
	}
}

.ant-btn-link {
	color: #f94e2f;
}

.ant-btn-link:hover {
	color: #f3816d;
}

.ant-btn-link:focus {
	color: #f3816d;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
	border-color: #f94e2f;
}
.ant-btn {
	border-radius: 4px;
}

.ant-btn-primary {
	background: #f94e2f;
	border-color: #f94e2f;
}

.ant-btn-primary:hover {
	background: #f3816d;
	border-color: #f94e2f;
}
.ant-btn-primary:focus {
	background: #f94e2f;
	border-color: #f94e2f;
}

.ant-input:focus {
	border-color: #986199;
	box-shadow: none;
}

.ant-input:hover {
	border-color: #986199;
}
.ant-picker:focus {
	border-color: #986199;
	box-shadow: none;
}

.ant-picker:hover {
	border-color: #986199;
}

.ant-picker-focused {
	border-color: #986199;
	box-shadow: none;
}

.w-70 {
	width: 70% !important;
}

.w-80 {
	width: 80% !important;
}

/* The container <div> - needed to position the dropdown content */
.dropdown-custom {
	position: relative;
	display: inline-block;
}

.estimate-home-breadcrumb {
	.breadcrumb {
		background-color: white;
	}
}

.ant-dropdown-menu {
	border-radius: 6px;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
	display: none;
	position: absolute;
	background-color: #fcfcfc;
	min-width: max-content;
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
	z-index: 1;
	border-radius: 5px;
}

/* Links inside the dropdown */
.dropdown-content a {
	color: var(--mainColor);
	padding: 12px 16px;
	text-decoration: none;
	display: block;
}

.ant-breadcrumb li:last-child {
	color: var(--colorGrayTwo);
}

/* Change color of dropdown links on hover */
// .dropdown-content a:hover {
// 	background-color: #f1f1f1;
// }

/* Show the dropdown menu on hover */
.dropdown-custom:hover .dropdown-content {
	display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
	background-color: #3e8e41;
}

//

.collapse-opening-timings {
	.ant-collapse-header {
		padding: 0px !important;
	}
}

.ant-popover-inner {
	border-radius: 10px;
}

.home-rating {
	label {
		margin-bottom: 0px !important;
		margin-top: 5px !important;
	}
}

.model-open {
	padding-right: 0px !important;
}

.home-rating-small {
	display: block !important;
	padding: 0px 5px !important;
	label {
		margin-bottom: 3px !important;
		margin-top: 0px !important;
	}
}

.review-rating {
	label {
		margin-bottom: 0px !important;
	}
}

.lg-modal {
	.ant-drawer-content-wrapper {
		width: 100vw !important;
		@media (min-width: 768px) {
			width: 500px !important;
		}
	}
}

.ant-upload.ant-upload-drag {
	border: 2px dashed #40a9ff;
	padding: 8px;
	border-radius: 8px;
}

.ant-upload {
	background: #f2f6ff;
	border-radius: 8px;
}

.ant-select-selector {
	border-radius: 60px !important;
	height: 48px !important;
}

.ant-select-selection-item {
	// line-height: 52px !important;
	padding-left: 10px !important;
}

body {
	margin: unset;
	background-color: var(--bg-blue);
	font-family: var(--fontNunito);
	margin-top: var(--headerHeightMobile);
	@media (min-width: 768px) {
		margin-top: var(--headerHeight);
	}
	height: auto !important;
	overscroll-behavior-y: none;
}

.nunito-bold {
	font-family: var(--fontNunitoBold) !important;
}

.website {
	width: 16px;
	height: 17px;
}

.map-modal {
	.gmnoprint,
	.gm-style-cc {
		display: none;
	}

	.close-btn {
		display: none;
	}

	.modal-lg {
		max-width: 990px;

		.modal-title {
			font-family: var(--fontNunitoBold);
			font-size: 18px;
			line-height: 25px;
		}

		.modal-header {
			border-radius: 10px 10px 0 0;
		}

		.modal-body {
			padding: unset;
			height: 720px;
		}
	}

	@media (min-width: 768px) and (max-width: 1439px) {
		.modal-lg {
			max-width: 644px;

			.modal-header {
				padding: 30px;
				align-items: center;
			}

			.modal-body {
				height: 490px;
			}
		}
	}
	@media (min-width: 320px) and (max-width: 767px) {
		.modal-lg {
			margin: unset;
			height: 100%;

			.modal-content {
				height: 100%;
			}

			.modal-header {
				padding: 30px;
				border-radius: unset;

				.modal-title {
					font-size: 16px;
					line-height: 22px;
				}
			}

			.close-btn {
				left: calc(50% - 155px);
				display: block;
				border: unset;
				font-size: 16px;
				line-height: 22px;
				font-family: var(--fontNunitoBold);
				position: absolute;
				bottom: 30px;
				width: 311px;
				height: 50px;
				color: var(--colorBlue);
				background: var(--colorWhite);
				box-shadow: 0 4px 10px rgba(56, 114, 239, 0.15);
				border-radius: 8px;
			}
		}
	}
}

.request-property-modal {
	color: var(--mainColor);

	.modal-header {
		padding: 30px 30px 0;
		border-bottom: unset;

		.modal-title {
			font-size: 20px;
			line-height: 27px;
			font-family: var(--fontNunitoBold);
		}
	}

	.modal-body {
		padding: 0 30px 30px;
	}

	.custom-req-price-modal {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-column-gap: 30px;

		.modal-btn-group {
			display: flex;
			justify-content: space-between;

			button {
				text-transform: capitalize;
				font-family: var(--fontNunitoBold);
				background-color: var(--colorBlue);
				font-size: 16px;
				line-height: 22px;
				width: 167px;
				height: 50px;
			}

			.cancel {
				background: unset;
				border: unset;
				color: var(--colorBlue);
			}
		}
	}

	.left-block {
		margin-top: 20px;
		max-width: 350px;
		font-size: 16px;
		line-height: 22px;

		label {
			font-size: 14px;
			line-height: 19px;
		}

		input::placeholder {
			color: #8f99b4;
			font-size: 16px;
			line-height: 22px;
		}
	}

	.right-block {
		.property-address {
			width: 60%;
			font-family: var(--fontNunitoBold);
			font-size: 18px;
			line-height: 25px;
			margin-bottom: 10px;
		}

		.property-location {
			position: relative;
			height: 95px;
			width: 350px;

			.gmnoprint,
			.gm-style-cc {
				display: none;
			}
		}

		.property-info {
			margin-top: 30px;
			display: grid;
			grid-template-columns: 1fr 1fr;

			&__item {
				display: flex;
				align-items: center;
				margin-bottom: 15px;

				img {
					width: 32px;
					height: 32px;
				}

				.info-block {
					font-size: 16px;
					line-height: 22px;
					margin-left: 12px;
					display: flex;
					flex-direction: column;

					.gray {
						font-size: 12px;
						line-height: 16px;
						opacity: 0.7;
					}
				}
			}
		}
	}

	@media (min-width: 768px) and (max-width: 1439px) {
		.modal-lg {
			max-width: 644px;
		}
		.custom-req-price-modal {
			display: flex;
			flex-direction: column-reverse;
			grid-template-columns: unset;

			.left-block,
			.right-block {
				max-width: unset;
			}

			.right-block {
				.property-address {
					margin: 20px 0;
					width: 100%;
				}

				.property-location {
					width: 100%;
				}

				.property-info {
					grid-template-columns: repeat(4, 1fr);

					.living-square {
						grid-column-start: 2;
						grid-row-start: 1;
					}
				}
			}
		}
	}

	@media (min-width: 320px) and (max-width: 767px) {
		.custom-req-price-modal {
			grid-template-columns: 1fr;

			.mobile-address {
				display: block;
				margin-top: 10px;
				font-size: 14px;
				line-height: 19px;
			}

			.left-block {
				max-width: unset;

				.modal-btn-group {
					flex-direction: column;

					button {
						width: 100%;
					}
				}
			}

			.right-block {
				display: none;
			}
		}
	}
}

.w-55 {
	width: 55% !important;
}

.w-45 {
	width: 45% !important;
}

.w-48 {
	width: 48% !important;
}

.w-40 {
	width: 40% !important;
}

.mt-57 {
	margin-top: 57px;
}

.ml-156 {
	margin-left: 156px;
}

.ml-67 {
	margin-left: 67px;
}

#price-block {
	//width: 130px;
	padding: 20px;
	height: 69px;
	z-index: 20;

	box-shadow: 0 4px 12px rgb(56 114 239 / 30%);
	border-radius: 8px;
	inset: auto auto 12px 0 !important;

	.gray {
		font-family: var(--fontNunito);
		font-size: 12px;
		line-height: 16px;
		color: var(--colorGrayTwo);
	}

	.tooltip-inner {
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		border-radius: unset;
		background-color: transparent;
		padding: unset;

		font-family: var(--fontNunitoBold);
		font-size: 18px;
		line-height: 25px;
		color: var(--mainColor);
	}

	.arrow {
		bottom: -6px;

		&::before {
			border-top-color: var(--colorWhite);
		}
	}

	@media (min-width: 320px) and (max-width: 900px) {
		opacity: 1;
	}
}

@media (max-width: 500px) {
	.botton-syicky {
		position: fixed;
		bottom: 0px;
		width: 93%;
	}
}

.custom-year-picker {
	border-radius: 6px;
}
