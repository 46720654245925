.ErrorPage {
  &__main-component {
    color: var(--mainColor);
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .image-bg {
      background: url("../../assets/images/404/404.png") no-repeat 100%;
      width: 640px;
      height: 263px;
      margin: 134px 0 42px;
    }

    h1 {
      font-family: var(--fontNunitoBold);
      font-size: 24px;
      line-height: 33px;
      margin-bottom: 12px;
    }

    p {
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 30px;
    }

    button {
      font-family: var(--fontNunitoBold);
      width: 224px;
      height: 50px;
      background: var(--colorBlue);
      border-radius: 8px;
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 133px;
    }

    @media (min-width: 320px) and (max-width: 767px) {
      .image-bg {
        background: url("../../assets/images/404/404-mobile.png") no-repeat 100%;
        width: 297px;
        height: 122px;
        margin: 73px 0 40px;
      }

      h1 {
        width: 65%;
        text-align: center;
      }

      button {
        margin-bottom: 91px;
      }
    }

    @media (min-width: 768px) and (max-width: 1439px) {
      .image-bg {
        background: url("../../assets/images/404/404-tablet.png") no-repeat 100%;
        width: 469px;
        height: 193px;
        margin: 125px 0 42px;
      }

      button {
       margin-bottom: 124px;
      }
    }
  }
}