@font-face {
	font-family: "Nunito";
	src: url("../assets/fonts/NunitoSans-Regular.ttf") format("truetype");
	font-display: swap;
}

@font-face {
	font-family: "NunitoBold";
	src: url("../assets/fonts/NunitoSans-Bold.ttf") format("truetype");
	font-display: swap;
}

@font-face {
	font-family: "Inter";
	src: url("../assets/fonts/Inter-Regular.ttf") format("truetype");
	font-display: swap;
}

@font-face {
	font-family: "InterBold";
	src: url("../assets/fonts/Inter-Bold.ttf") format("truetype");
	font-display: swap;
}

:root {
	--headerHeight: 67px;
	--headerHeightMobile: 60px;
	--mainColor: #1d2e5b;
	--bg-blue: #f2f6ff;
	--bg-gray: "#E4E5E9";

	--colorWhite: #ffffff;
	--colorBlack: #151a20;
	--colorLightGrey: #d2d5de;
	--colorGray: #8f99b4;
	--colorGrayTwo: #6c768f;
	--colorOrange: #fe7f2d;
	--colorlightOrange: #feb382;
	--colorCyan: #178d84;
	--colorLightBlue: #a9c0ff;
	--colorBlue: #3871ef;
	--colorLightBlue: #678fe6;

	--fontNunito: "Nunito", sans-serif;
	--fontNunitoBold: "NunitoBold", sans-serif;
	-webkit-font-smoothing: antialiased;
}
