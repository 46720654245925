.statistics {
	.statistics-container {
		min-height: 1000px;

		@media (min-width: 768px) and (max-width: 1199px) {
			min-height: 500px;
		}

		@media (min-width: 1200px) {
			min-height: 600px;
		}

		.statistics-iframe {
			border-radius: 0px;
			margin-top: 0px;
			@media (min-width: 768px) and (max-width: 1023px) {
				border-radius: 0px;
				margin-top: -2px;
			}
			@media (min-width: 1024px) {
				border-radius: 8px;
				margin-top: 0px;
			}
		}
	}
}
