.Login,
.ForgotPassword,
.ResetPassword {
	background-color: var(--colorWhite);
	min-height: calc(100vh - var(--headerHeight));

	.custom-alert {
		position: absolute;
		width: 100%;
		z-index: 20;
		top: 65px;
	}

	.bg-image {
		// position: absolute;
		// z-index: -1;
		// display: flex;
		// align-items: flex-end;
		// justify-content: center;

		background: url("../../assets/images/login-bg.png") no-repeat 100% 100%;
		background-size: cover;
		width: 100vw;
		height: 155px;
	}

	.link {
		cursor: pointer;
		color: var(--colorBlue);
		display: flex;
		gap: 0px;
		align-items: center;
		justify-content: center;
		img {
			transform: rotate(270deg);
		}
	}

	.desc {
		font-size: 14px;
		line-height: 19px;
		text-align: center;
		margin-bottom: 20px;
	}

	form {
		margin: 0 auto;
		max-width: 433px;

		label {
			font-size: 14px;
			line-height: 19px;
			margin: 20px 0 5px;
		}

		.link {
			display: block;
			font-size: 14px;
			line-height: 19px;
			margin: 10px 0 20px;
		}

		.form-group {
			margin-bottom: unset;
			position: relative;

			img {
				position: absolute;
				left: 14px;
				top: 58px;
			}
		}

		input {
			height: 46px;
			padding-left: 38px;
			border: 1px solid #8f99b4;
			border-radius: 6px;
		}

		.group-btn {
			.send-reminder {
				margin-top: 40px;
			}

			button {
				font-family: var(--fontNunitoBold);
				width: 100%;
				background: var(--colorBlue);
				height: 50px;
				border-radius: 8px;
				margin-bottom: 10px;
			}

			span {
				cursor: pointer;
				color: var(--colorBlue);
				font-family: var(--fontNunitoBold);
				width: 100%;
				display: block;
				height: 50px;
				text-align: center;
				line-height: 22px;

				img {
					margin-right: 9px;
				}
			}
		}
	}

	.short-footer {
		//position: absolute;s
		// margin: 0 auto -60px;
		// position: fixed;
		bottom: 0px;
		width: 100%;
		padding: 0 40px 30px;
		font-size: 14px;
		line-height: 19px;

		p {
			margin-bottom: unset;
		}
	}

	@media (min-width: 320px) and (max-width: 500px) {
		position: relative;
		min-height: 100vh;
		height: unset;

		// h1 {
		// 	position: absolute;
		// 	top: 145px;
		// }

		.desc {
			// top: 133px;
			// left: -webkit-calc(50% - 103px);
			// left: calc(50% - 100px);
			// margin-top: 30px;
		}
		.link {
			cursor: pointer;
			color: var(--colorBlue);
			// margin-top: 10px;
			display: flex;
			justify-content: center;
			flex-direction: row;
			gap: 0px;

			img {
				transform: rotate(270deg);
			}
		}
		form {
			padding: 0 16px;
		}

		.short-footer {
			flex-direction: column;
			align-items: center;
			p {
				display: flex;
				flex-direction: column;
				align-items: center;
			}
		}
	}
}
.LoginForm,
.ForgotPasswordForm,
.ResetPasswordForm {
	background-color: var(--colorWhite);

	.custom-alert {
		position: absolute;
		width: 100%;
		z-index: 20;
		top: 65px;
	}

	.bg-image {
		display: flex;
		align-items: flex-end;
		justify-content: center;

		background: url("../../assets/images/login-bg.png") no-repeat 100% 100%;
		background-size: cover;
		width: 100%;
		height: 155px;

		h1 {
			font-family: var(--fontNunitoBold);
			line-height: 33px;
		}
	}

	.link {
		cursor: pointer;
		color: var(--colorBlue);

		img {
			transform: rotate(270deg);
		}
	}

	.desc {
		font-size: 14px;
		line-height: 19px;
		text-align: center;
		margin-bottom: 20px;
	}

	form {
		margin: 0 auto;
		max-width: 433px;

		label {
			font-size: 14px;
			line-height: 19px;
			margin: 20px 0 5px;
		}

		.link {
			display: block;
			font-size: 14px;
			line-height: 19px;
			margin: 10px 0 20px;
		}

		.form-group {
			margin-bottom: unset;
			position: relative;

			img {
				position: absolute;
				left: 14px;
				top: 58px;
			}
		}

		input {
			height: 46px;
			padding-left: 38px;
			border: 1px solid #8f99b4;
			border-radius: 6px;
		}

		.group-btn {
			.send-reminder {
				margin-top: 40px;
			}

			button {
				font-family: var(--fontNunitoBold);
				width: 100%;
				background: var(--colorBlue);
				height: 50px;
				border-radius: 8px;
				margin-bottom: 10px;
			}

			span {
				cursor: pointer;
				color: var(--colorBlue);
				font-family: var(--fontNunitoBold);
				width: 100%;
				display: block;
				height: 50px;
				text-align: center;
				line-height: 22px;

				img {
					margin-right: 9px;
				}
			}
		}
	}

	.short-footer {
		position: fixed;
		bottom: 0px;
		width: 100%;
		padding: 0 40px 30px;
		font-size: 14px;
		line-height: 19px;

		p {
			margin-bottom: unset;
		}
	}

	@media (min-width: 320px) and (max-width: 900px) {
		position: relative;
		min-height: 100vh;
		height: unset;

		h1 {
			position: absolute;
			top: 145px;
		}

		.desc {
			// top: 133px;
			// left: -webkit-calc(50% - 103px);
			// left: calc(50% - 100px);
			// margin-top: 30px;
		}
		.link {
			cursor: pointer;
			color: var(--colorBlue);
			display: flex;
			justify-content: center;

			img {
				transform: rotate(270deg);
			}
		}
		form {
			padding: 0 16px;
		}

		.short-footer {
			flex-direction: column;
			align-items: center;

			p {
				display: flex;
				flex-direction: column;
				align-items: center;
			}
		}
	}
}
.modal-popup {
	.ant-modal-content {
		border-radius: 10px;
		overflow: hidden;
	}
}
.block-container {
	filter: blur(8px);
	-webkit-filter: blur(8px);
}
.form-remind-button {
	color: var(--colorBlue);
	cursor: pointer;
	font-size: 14px;
	margin-top: -8px;
	font-weight: bold;
	text-decoration: underline;
}
.form-remind-btn {
	color: var(--colorBlue);
	cursor: pointer;
	font-size: 14px;
	margin-top: -10px;
}

.login-page {
	background-color: white;
	min-height: calc(100vh - var(--headerHeight));
	.page-title {
		font-family: "Nunito Sans";
		font-style: normal;
		font-weight: 700;
		font-size: 24px;
		line-height: 33px;
		text-align: center;
		color: #1d2e5b;
	}

	.page-subtitle {
		font-family: "Nunito Sans";
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 18px;
		color: #1d2e5b;
	}

	.login-page-link {
		font-family: "Nunito Sans";
		font-style: normal;
		font-weight: 700;
		font-size: 14px;
		line-height: 19px;
		text-decoration-line: underline;
		color: #3871ef;
	}

	.short-footer {
		bottom: 0px;
		width: 100%;
		padding: 0 40px 30px;
		font-size: 14px;
		line-height: 19px;
		margin-top: auto;
		padding-top: 50px;
		p {
			margin-bottom: unset;
		}

		@media (min-width: 320px) and (max-width: 500px) {
			flex-direction: column;
			align-items: center;
			p {
				display: flex;
				flex-direction: column;
				align-items: center;
			}
		}
	}
}

.login-page-winleads {
	background-color: white;
	// min-height: 90vh;
	height: 100%;
	overflow: hidden;
	position: absolute;
    top: 0;
    width: 100%;
	padding-top: 30px;
	.page-title {
		font-family: "Nunito Sans";
		font-style: normal;
		font-weight: 700;
		font-size: 24px;
		line-height: 33px;
		text-align: center;
		color: #1d2e5b;
	}

	.page-subtitle {
		font-family: "Nunito Sans";
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 18px;
		color: #1d2e5b;
	}

	.login-page-link {
		font-family: "Nunito Sans";
		font-style: normal;
		font-weight: 700;
		font-size: 14px;
		line-height: 19px;
		text-decoration-line: underline;
		color: #3871ef;
	}

	.short-footer {
		bottom: 0px;
		width: 100%;
		padding: 0 40px 30px;
		font-size: 14px;
		line-height: 19px;
		margin-top: auto;
		padding-top: 50px;
		p {
			margin-bottom: unset;
		}

		@media (min-width: 320px) and (max-width: 500px) {
			flex-direction: column;
			align-items: center;
			p {
				display: flex;
				flex-direction: column;
				align-items: center;
			}
		}
	}
}