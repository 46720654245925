.Terms,
.PrivacyPolicy,
.Gdpr {
	background-color: var(--colorWhite);
	color: var(--mainColor);
	font-size: 14px;
	padding-bottom: 31px;

	.blue-line {
		height: 4px;
		width: 100%;
		background: var(--colorBlue);
	}

	.link {
		cursor: pointer;
		color: #3871ef;
		gap: 0px;
		align-items: center;
		justify-content: center;
	}

	.term-conditions-container {
		margin-top: 40px;

		width: 100%;
		@media (min-width: 768px) and (max-width: 1023px) {
			width: 70%;
		}
		@media (min-width: 1024px) {
			width: 45%;
		}

		h1 {
			font-size: 24px;
			line-height: 33px;
			text-align: center;
			font-weight: bold;
			text-transform: uppercase;
			margin-bottom: 20px;
			font-family: var(--fontNunitoBold);
			color: var(--mainColor);
		}

		h2 {
			width: 100%;
			border-bottom: 1px solid var(--colorLightGrey);
			margin: 50px 0 30px;
			text-transform: uppercase;
			font-family: var(--fontNunitoBold);
			display: block;
			margin-bottom: 10px;
			font-size: 20px;
			line-height: 27px;
			color: var(--mainColor);
		}
	}

	.main-content {
		max-width: 665px;
		width: 50%;
		margin: 41px auto 0;

		.data {
			display: block;
			text-align: center;
			margin: 5px 0 50px;
		}

		h4 {
			// text-transform: uppercase;
			max-width: 598px;
			margin: 0 auto;
			text-align: center;
			line-height: 33px;
			font-family: var(--fontNunitoBold);
			color: var(--colorBlue);
		}

		.new-segment {
			width: 100%;
			border-bottom: 1px solid black;
			margin: 50px 0 30px;
			color: var(--colorBlue);

			span {
				// text-transform: uppercase;
				font-family: var(--fontNunitoBold);
				display: block;
				margin-bottom: 10px;
				font-size: 20px;
				line-height: 27px;
				color: var(--colorBlue);

			}
		}

		.double-block {
			display: flex;
			margin-bottom: 20px;

			&__label {
				line-height: 140%;
				min-width: 50px;
				// margin-right: 10px;
				font-family: var(--fontNunitoBold);
			}

			&__text {
				.roman-items {
					.first {
						&:before {
							content: "I.";
						}
					}

					.second {
						&:before {
							content: "II.";
						}
					}

					.third {
						&:before {
							content: "III.";
						}
					}

					.fourth {
						&:before {
							content: "IV.";
						}
					}

					.fifth {
						&:before {
							content: "V.";
						}
					}

					.sixth {
						&:before {
							content: "VI.";
						}
					}

					.seventh {
						&:before {
							content: "VII.";
						}
					}

					.eighth {
						&:before {
							content: "VIII.";
						}
					}

					.ninth {
						&:before {
							content: "IX.";
						}
					}

					.tenth {
						&:before {
							content: "X.";
						}
					}
				}

				ul {
					margin: 20px 0 0 0;
					padding-left: 0;
					list-style-type: none;

					li {
						position: relative;
						margin-bottom: 10px;
						list-style-type: none;


						&:before {
							content: "";
							position: absolute;
							left: -40px;
							width: 30px;
						}

						&::marker {
							position: absolute;
							left: -40px;
						}
					}
				}
			}
		}

		p {
			line-height: 140%;
			text-align: justify;
		}

		ul {
			li {
				list-style-type: none;
				margin-bottom: 20px;
				line-height: 140%;
			}
		}

		table,
		th,
		td {
			border: 1px solid black;
			border-collapse: collapse;
			margin-bottom: 20px;
			padding: 5px;
			font-size: 10px;
			text-align: center;
			@media (min-width: 768px) {
				padding: 10px;
				font-size: 14px;
			}
		}
	}

	@media (min-width: 320px) and (max-width: 900px) {
		.main-content {
			margin-top: 45px;
			width: 100%;
			padding: 0 16px;

			h4 {
				font-size: 20px;
				line-height: 27px;
				display: flex;
				flex-direction: column;
			}
		}
	}
}
