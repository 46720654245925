.footer {
	background: var(--mainColor);

	.two-container {
		flex: 2;
		display: flex;
		flex-direction: row;
		justify-content: space-around;
	}

	.container {
		display: flex;
		padding-top: 30px;
		padding-bottom: 85px;
		gap: 40px;
		.logo-container {
			flex: 1;
			display: flex;
			// flex-direction: column;
			font-family: "Nunito Sans";
			font-size: 12px;
			line-height: 16px;
			color: #ffffff;
			opacity: 0.6;
			.image-container {
				position: relative;
				width: 131px;
				height: 38px;
				margin-bottom: 10px;
			}

			.footer-description {
				text-align: start;
				@media (max-width: 767px) {
					text-align: center;
				}
			}
		}

		.options-container {
			flex: 1;
			h5 {
				font-family: "Nunito Sans";
				font-style: normal;
				font-weight: 700;
				font-size: 16px;
				line-height: 22px;
				color: #a9c0ff;
			}

			.options {
				.option {
					font-family: "Nunito Sans";
					font-style: normal;
					font-weight: 400;
					font-size: 14px;
					line-height: 19px;

					margin-top: 5px;
					a {
						color: #f2f6ff;
					}
				}
			}
		}

		.social-container {
			// flex: 1;
			h5 {
				font-family: "Nunito Sans";
				font-style: normal;
				font-weight: 700;
				font-size: 16px;
				line-height: 22px;
				color: #a9c0ff;
			}

			// .social-icons {
			//   a {
			//     margin-right: 10px;
			//   }
			// }
		}
		.get-in-touch {
			flex: 1;
			h5 {
				font-family: "Nunito Sans";
				font-style: normal;
				font-weight: 700;
				font-size: 16px;
				color: #a9c0ff;
			}

			h4 {
				font-family: "Nunito Sans";
				font-style: normal;
				font-weight: 700;
				font-size: 20px;

				color: #ffffff;
				margin: 12px 0;
			}
			.lines {
				display: flex;
				flex-direction: row;
				align-items: center;
			}
		}
	}

	.all-rights-container {
		height: 56px;
		background: #1a2a52;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		padding: 20px 156px;
		font-family: "Nunito Sans";
		font-style: normal;
		font-weight: 400;
		font-size: 12px;
		line-height: 16px;
		color: #6c768f;

		.copyright {
		}

		a {
			color: #6c768f;
		}
	}

	.collapse-options {
		display: none;
	}

	@media (max-width: 1023px) {
		.two-container {
			margin-top: 20px;
			flex: 1;
			justify-content: space-between;
		}

		.one-container {
			flex: 1;
		}

		.container {
			display: flex;
			flex-direction: column;
			padding: 32px;
			gap: 10px;
		}

		.all-rights-container {
			flex-direction: row;
			height: 87px;
			padding-left: 20px;
			padding-right: 20px;
		}

		// .social-container {
		//   display: flex;
		//   flex-direction: column;
		// }

		.collapse-options {
			display: none;
		}
	}
	@media (max-width: 600px) {
		.two-container {
			flex-direction: column;
		}
		.container {
			display: flex;
			flex-direction: column;
			padding: 32px;
			gap: 10px;
		}

		.all-rights-container {
			flex-direction: column;
			height: 87px;
			padding-left: 0px;
			padding-right: 0px;
		}

		// .social-container {
		//   display: flex;
		//   flex-direction: row;
		//   align-items: center;
		//   justify-content: space-between;
		// }

		.options-container {
			display: none;
		}

		.collapse-options {
			display: block;
			margin-top: 10px;
			svg {
				color: #a9c0ff;
			}
			h5 {
				font-family: "Nunito Sans";
				font-style: normal;
				font-weight: 700;
				font-size: 16px;
				line-height: 22px;
				color: #a9c0ff;
			}

			.ant-collapse-header {
				padding: 0px;
			}

			.ant-collapse-content-box {
				padding: 0px !important;
				margin: 0px;

				.options {
					.option {
						font-family: "Nunito Sans";
						font-style: normal;
						font-weight: 400;
						font-size: 14px;
						line-height: 19px;
						margin-top: 5px;
						a {
							color: #f2f6ff;
						}
					}
				}
			}
		}
	}
}
