.seller-leads-container {
	.title {
		//styleName: Platform/H4;
		font-family: "Nunito Sans";
		font-size: 24px;
		font-weight: 700;
		line-height: 33px;
	}
}

.icons-selection-list {
	.custom-status-icon {
		color: #71849c !important;
	}
	.custom-status-icon-selected {
		color: #fc704c !important;
	}
}

.custom-callback-switch {
	.ant-switch-checked {
		background-color: #550856;
	}
}

.hovered {
	color: #FC704C !important;
}

.ant-pagination-item {
	border-radius: 4px;
	border: none;
}

.ant-pagination-item:hover {
	border: 1px solid #550856;
	color: #FFFF;
	background-color: #550856;
}

.ant-pagination-next .ant-pagination-item-link {
	border: none;
	border-radius: 4px;
}

.ant-pagination-prev .ant-pagination-item-link {
	border: none;
	border-radius: 4px;
}

.ant-pagination-item-active {
	background: #550856;
	color: white;
	border-radius: 4px;
	border: none;
}

.sort-heading {
	font-family: "Nunito Sans";
	font-size: 12px;
	font-weight: 700;
	line-height: 16px;
	color: #8f99b4;
	margin-left: 13px;
}

.custom-table {
	.ant-table-container {
		border-radius: 6px !important;
		overflow: hidden;
		z-index: 10;
	}
}

.detail-modal-container {
	padding: 10px;

	@media (min-width: 1024px) {
		padding: 30px;
	}

	.first-block {
		background: #f7f3f7;
		width: 100%;
		padding: 10px;
		border-radius: 8px;

		@media (min-width: 1024px) {
			width: 50%;
			padding: 15px;
		}
	}

	.second-block {
		// background: #f9faff;
		width: 100%;
		padding: 10px;

		@media (min-width: 1024px) {
			width: 50%;
			padding: 15px;
		}

		.detail-lists {
			margin-top: 5px;

			:nth-child(even) {
				background-color: white !important;
			}

			.detail-row {
				display: flex;
				flex-direction: row;
				align-items: center;
				padding: 7px;
				color: var(--mainColor);
				background-color: #f7f3f7;
				border-radius: 6px;

				.detail-row-title {
					width: 70%;
					// background-color: white;
				}

				.value-cont {
					background: transparent !important;
				}
			}
		}
	}
}

.lead-detail-modal {
	.modal-dialog {
		max-width: 616px !important;
	}

	@media (min-width: 1130px) {
		.modal-dialog {
			max-width: 1000px !important;
		}
	}

	@media (max-width: 597px) {
		.modal-dialog {
			max-width: 100% !important;
			margin: 0px !important;
			padding-left: 0;
		}
	}

	.next-arrow-prop {
		position: absolute;
		right: -55px;
		bottom: 45%;
		width: 40px;
		height: 40px;
		padding: 10px;
		border-radius: 100px;
		border: 1px solid #722573;
		gap: 10px;
		background-color: #550856;
		display: flex;
		justify-content: center;
		align-items: center;

		@media (max-width: 1130px) {
			bottom: 60%;
		}
	}

	.prev-arrow-prop {
		position: absolute;
		left: -55px;
		bottom: 45%;
		width: 40px;
		height: 40px;
		padding: 10px;
		border-radius: 100px;
		border: 1px solid #722573;
		gap: 10px;
		background-color: #550856;
		display: flex;
		justify-content: center;
		align-items: center;

		@media (max-width: 1130px) {
			bottom: 60%;
		}
	}

	.arrow-container {
		position: fixed;
		display: flex;
		flex-direction: row;
		background: #ffff;
		padding: 12px 20px;
		justify-content: space-between;
		border-top: 1px solid #e0e5eb;
		bottom: 0;
		z-index: 3;
		margin: 0;
		width: 100%;
		margin-left: -15px;

		.next-arrow-prop2 {
			// position: absolute;
			width: 40px;
			height: 40px;
			padding: 10px;
			border-radius: 100px;
			border: 1px solid #722573;
			gap: 10px;
			background-color: #550856;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.prev-arrow-prop2 {
			// position: absolute;
			width: 40px;
			height: 40px;
			padding: 10px;
			border-radius: 100px;
			border: 1px solid #722573;
			gap: 10px;
			background-color: #550856;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
}

.icons {
	height: 16px;
	width: 16px;
	object-fit: scale-down;
}

@media (max-width: 540px) {
	.info-row {
		flex-direction: column !important;
	}
}
.parent-row {
	border-radius: 6px;
	box-shadow: 0px 2px 3px 1px #1d2e5b0f;
	padding: 12px;
	background-color: #ffff;
	margin: 0px 10px 10px 10px;
}
.font-style {
	color: var(--immo-belgium-blue-4, #1d2e5b);
	font-family: "Nunito Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	line-height: 24px;
}

.font-style-1 {
	color: var(--immo-belgium-blue-4, #1d2e5b);
	font-family: "Nunito Sans";
	font-size: 12px;
	font-style: normal;
	font-weight: 700;
	line-height: 18px;
}

.border-bottom-add {
	margin-top: -5px;
	margin-bottom: 15px;
	border-bottom: 1px solid #e0e5eb;
	padding: 0px;
	width: 100%;
}

.filter-button {
	display: flex;
	padding: 11px;
	justify-content: center;
	align-items: center;
	gap: 6px;
	border-radius: 6px;
	border: 1px solid #3871ef;
	background: #3871ef;
}

.title-modal {
	color: #1d2e5b;
	font-family: "Nunito Sans";
	font-size: 20px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}

.filter-status-style {
	color: #1d2e5b;
	font-family: "Nunito Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}

.filters-clear-btn {
	display: flex;
	padding: 26px;
	justify-content: space-between;
	align-items: flex-start;
	align-self: stretch;
	margin-top: 15px;
	border-top: 1px solid #e5eaf7;
}

.btn-clear {
	color: #ef4a1e;
	background: transparent;
	border: none;
	font-family: "Nunito Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	line-height: 18px;
	box-sizing: none;
	box-shadow: none;
	margin-left: -20px;
}

.clear-content {
	display: flex;
	align-items: center;
}

.clear-icon {
	margin-right: 8px;
}

.btn-view {
	display: flex;
	padding: 14px 20px;
	justify-content: center;
	align-items: center;
	gap: 6px;
	width: 110px;
	border-radius: 6px;
	border: 1px solid #3871ef;
	background: #3871ef;
	margin-right: -14px;
	overflow-x: hidden;
	overflow-y: hidden;
}

.pagination-container {
	display: flex;
	align-items: center;
	// justify-content: flex-end;
	gap: 10px;
}

.page-size-option {
	cursor: pointer;
	border-radius: 4px;
	display: flex;
	align-items: center;
	padding: 10px;
	height: 36px;
	width: 36px;
	text-align: center;
	background: #FFFF;
	box-shadow: 0px 2px 3px 1px #1d2e5b0f;
}

.page-size-option.selected {
	border: 1px solid #fc704c;
}

.page-size-option:hover {
	border: 1px solid #fc704c;
}

.line-styling {
	padding: 0px 5px;
	color: black;
}

@media (max-width: 768px) {
	.pagination-container {
		justify-content: center;
		flex-wrap: wrap;
		gap: 0px;
	}

	.page-size-option {
		margin-right: 10px;
	}

	.line-styling {
		padding: 0px 1px;
		color: black;
	}
}
