.nav-bar {
	height: max-content;
	padding: 10px 0;

	background: var(--colorWhite);
	border-radius: 10px;

	font-size: 14px;
	line-height: 19px;

	ul {
		list-style-type: none;

		.active {
			border-left: 3px solid #FC704C;
			font-weight: bold;
			color: rgb(0, 0, 0);
		}

		li {
			display: flex;
			align-items: center;
			cursor: pointer;
			width: 202px;
			height: 43px;

			img {
				margin: 0 6px 0 20px;
			}
		}
	}

	@media (max-width: 768px) {
		margin-right: unset;
		padding: unset;

		ul {
			display: flex;
			justify-content: space-between;

			li {
				width: 100%;
				height: 53px;
				justify-content: center;
				font-size: 12px;
			}

			.active {
				border-left: unset;
				border-bottom: 2px solid var(--colorBlue);
			}
		}
	}

	// @media (max-width: 768px) {
	// 	display: none;
	// }
}
