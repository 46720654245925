.main-container {
	background-color: white;
	// display: flex;
	// flex-direction: column;
	// align-items: center;

	.cover-block {
		width: 100%;
		height: auto;
		position: relative;

		.dashboard-image {
			height: 310px;
			position: relative;

			img {
				object-position: left top;
			}

			margin-left: 20px;

			@media (min-width: 768px) and (max-width: 1023px) {
				height: 500px;
			}

			@media (min-width: 1024px) {
				height: 500px;

				img {
					object-position: bottom center;
				}
			}
		}

		.availability-button {
			background: #fc704c;
			color: white;
			width: 90%;
			border-radius: 8px;
			border: none;
			margin-bottom: 30px;
			height: 45px;
			font-family: InterBold;
			cursor: pointer;

			@media (min-width: 768px) {
				width: min-content;
				height: 50px;
			}
		}

		.our-team-text {
			font-family: Inter;
			font-size: 16px;
			line-height: 22.4px;
			text-align: center;
			color: #ffffff;
			opacity: 0.72;
			width: 95%;

			@media (min-width: 768px) and (max-width: 1023px) {
				font-size: 22px;
				line-height: 31px;
				width: 70%;
			}

			@media (min-width: 1024px) {
				font-size: 22px;
				line-height: 30.8px;
				width: 35%;
			}
		}

		.generate-more-sales-text {
			font-family: InterBold;
			font-size: 36px;
			line-height: 47px;
			letter-spacing: -0.01em;
			text-align: center;
			color: white;
			width: 90%;
			margin-top: 40px;

			@media (min-width: 768px) and (max-width: 1023px) {
				font-size: 46px;
				line-height: 60px;
				width: 90%;
			}

			@media (min-width: 1024px) {
				font-size: 64px;
				line-height: 76.8px;
				width: 70%;
				margin-top: 80px;
			}
		}
	}

	.sections-block {
		padding-left: 15px;
		padding-right: 15px;
		display: flex;
		flex-direction: column;
		align-items: center;

		background: linear-gradient(
			180deg,
			rgba(255, 255, 255, 1) 33%,
			rgb(237, 238, 240) 100%
		);

		// @media (min-width: 768px) and (max-width: 1023px) {
		// 	padding-left: 25px;
		// 	padding-right: 25px;
		// 	padding-top: 20px;
		// 	padding-bottom: 40px;
		// }

		// @media (min-width: 1024px) {
		// 	padding-left: 50px;
		// 	padding-right: 50px;
		// 	padding-top: 30px;
		// 	padding-bottom: 30px;
		// }

		// @media (min-width: 1200px) and (max-width: 1439px) {
		// 	padding-left: 180px;
		// 	padding-right: 180px;
		// 	padding-top: 50px;
		// 	padding-bottom: 50px;
		// }
		// @media (min-width: 1440px) and (max-width: 1800px) {
		// 	padding-left: 220px;
		// 	padding-right: 220px;
		// 	padding-top: 64px;
		// 	padding-bottom: 64px;
		// }

		// @media (min-width: 1801px) {
		// 	padding-left: 20%;
		// 	padding-right: 20%;
		// 	padding-top: 64px;
		// 	padding-bottom: 64px;
		// }

		.sections-block-inner {
			width: 100%;
			padding-top: 20px;
			@media (min-width: 1024px) and (max-width: 1100px) {
				padding-top: 0px;
			}

			@media (min-width: 1101px) {
				width: 64px;
			}

			@media (min-width: 1024px) {
				width: 996px;
			}
		}

		.step-text {
			font-family: Inter;
			font-size: 14px;
			font-weight: 600;
			line-height: 18.2px;
			text-align: left;
			color: #fc704c;
		}

		.heading-text {
			font-family: InterBold;
			font-size: 28px;
			line-height: 36.4px;
			text-align: center;
			color: #151a20;

			@media (min-width: 768px) {
				font-size: 24px;
				line-height: 31.2px;
				text-align: left;
			}
		}

		.description-text {
			font-family: Inter;
			font-size: 16px;
			font-weight: 400;
			line-height: 24px;
			text-align: left;
			color: #46576d;

			@media (min-width: 768px) {
				font-size: 14px;
				line-height: 21px;
			}
		}

		.section-layout {
			gap: 40px;
			padding-bottom: 40px;
			padding-top: 40px;
			display: flex;
			flex-direction: column;

			@media (min-width: 768px) and (max-width: 1023px) {
				gap: 40px;
				padding-bottom: 0px;
				padding-top: 0px;
			}

			@media (min-width: 1024px) and (max-width: 1200px) {
				gap: 64px;
				padding-bottom: 30px;
				padding-top: 30px;
			}

			@media (min-width: 1201px) and (max-width: 1439px) {
				gap: 64px;
				padding-bottom: 40px;
				padding-top: 40px;
			}

			@media (min-width: 1440px) {
				gap: 64px;
				padding-bottom: 64px;
				padding-top: 64px;
			}

			.text-section {
				gap: 24px;
				flex: 1;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;

				@media (min-width: 768px) {
					align-items: start;
				}
			}

			.image-section {
				height: 360px;
				flex: 1;
				position: relative;
				display: none;

				@media (min-width: 768px) {
					display: block;
				}
			}
		}

		.section-1 {
			@media (min-width: 768px) {
				flex-direction: row;
			}

			.heading-text {
				width: 245px;

				@media (min-width: 768px) {
					width: 210px;
				}
			}
		}

		.section-2 {
			@media (min-width: 768px) {
				flex-direction: row-reverse;
			}
		}

		.section-3 {
			@media (min-width: 768px) {
				flex-direction: row;
			}

			// .heading-text {
			// 	width: 210px;

			// 	@media (min-width:)
			// }
		}

		.section-4 {
			@media (min-width: 768px) {
				flex-direction: row-reverse;
			}
		}

		.section-5 {
			@media (min-width: 768px) {
				flex-direction: row;
			}
		}
	}

	.proven-way-block-outer {
		display: flex;
		align-items: center;
		justify-content: center;
		.proven-way-block {
			padding-left: 16px;
			padding-right: 16px;
			padding-top: 48px;
			background-color: white;
			width: 100%;

			@media (min-width: 1024px) {
				width: 1200px;
			}

			@media (min-width: 768px) and (max-width: 1023px) {
				// padding-left: 24px;
				// padding-right: 24px;
				padding-top: 60px;
			}

			@media (min-width: 1024px) and (max-width: 1199px) {
				// padding-left: 120px;
				// padding-right: 120px;
				padding-top: 80px;
			}

			@media (min-width: 1200px) {
				// padding-left: 120px;
				// padding-right: 120px;
				padding-top: 150px;
			}

			.proven-way-text {
				font-family: InterBold;
				font-size: 28px;
				line-height: 37px;
				text-align: left;
				color: #151a20;
				width: 90%;
				text-align: left;
				margin-bottom: 24px;

				@media (min-width: 768px) and (max-width: 1023px) {
					font-size: 36px;
					line-height: 46.8px;
					width: 80%;
					margin-bottom: 40px;
					text-align: center;
				}

				@media (min-width: 1024px) and (max-width: 1199px) {
					font-size: 36px;
					line-height: 46.8px;
					width: 300px;
					margin-bottom: 50px;
				}

				@media (min-width: 1200px) {
					font-size: 36px;
					line-height: 46.8px;
					width: 300px;
					margin-bottom: 0px;
				}
			}

			.orange-card {
				background-color: #fc704c;
				border-radius: 12px;
				width: 100%;
				padding: 32px;
				color: white;
				display: flex;
				flex-direction: column;
				gap: 20px;

				@media (min-width: 1024px) {
					width: 300px;
				}

				.heading-text {
					font-family: Inter;
					font-size: 20px;
					font-weight: 700;
					line-height: 28px;
					text-align: left;
				}

				.description-text {
					font-family: Inter;
					font-size: 16px;
					font-weight: 500;
					line-height: 22.4px;
					text-align: left;
				}

				.button-text {
					font-family: Inter;
					font-size: 16px;
					font-weight: 700;
					line-height: 22.4px;
					// text-align: center;
				}
			}
		}
	}

	.firms-block {
		padding-left: 16px;
		padding-right: 16px;
		padding-top: 40px;
		background-color: white;

		img {
			width: 50%;
			object-fit: contain;
			height: 100px;
		}

		@media (min-width: 768px) and (max-width: 1023px) {
			padding-left: 25px;
			padding-right: 25px;
			padding-top: 60px;

			img {
				width: 20%;
			}
		}

		@media (min-width: 1024px) {
			padding-left: 120px;
			padding-right: 120px;
			padding-top: 150px;

			img {
				width: 20%;
			}
		}

		.keep-the-trusted-text {
			font-family: InterBold;
			font-size: 18px;
			line-height: 25px;
			text-align: left;
			color: #151a20;
			padding-left: 20px;

			@media (min-width: 768px) and (max-width: 1023px) {
				font-size: 24px;
				line-height: 33.6px;
				text-align: center;
				padding-left: 0px;
			}

			@media (min-width: 1024px) {
				font-size: 24px;
				line-height: 33.6px;
				text-align: center;
				padding-left: 0px;
			}
		}
	}

	.its-clear-block {
		background-color: white;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-left: 16px;
		padding-right: 16px;
		padding-top: 80px;
		padding-bottom: 120px;

		@media (min-width: 768px) and (max-width: 1023px) {
			padding-left: 0px;
			padding-right: 0px;
			padding-top: 60px;
			padding-bottom: 120px;
		}

		@media (min-width: 1023px) and (max-width: 1199px) {
			padding-left: 20px;
			padding-right: 20px;
			padding-top: 80px;
			padding-bottom: 120px;
		}

		@media (min-width: 1200px) {
			padding-left: 120px;
			padding-right: 120px;
			padding-top: 120px;
			padding-bottom: 120px;
		}

		.it-is-clear-text {
			font-family: Inter;
			font-size: 16px;
			font-weight: 600;
			line-height: 17.6px;
			text-align: center;
		}

		.dont-take-our-word {
			font-family: InterBold;
			font-size: 28px;
			line-height: 37px;
			width: 80%;
			text-align: center;

			@media (min-width: 768px) and (max-width: 1023px) {
				font-size: 36px;
				line-height: 47px;
			}

			@media (min-width: 1024px) {
				font-size: 40px;
				line-height: 44px;
			}
		}

		.review-card {
			display: flex;
			cursor: pointer;
			flex-direction: column;
			justify-content: space-between;
			min-height: 500px;
			flex: 1;
			border: 1px solid #fc704c;
			border-radius: 16px;
			padding: 30px;
			gap: 60px;
			transition: all 600ms;

			.quote {
				font-size: 25px;
				color: #fc704c;
			}

			.review-text {
				font-family: Inter;
				font-size: 14px;
				font-weight: 600;
				line-height: 18.2px;
				text-align: left;
				color: #151a20;
				gap: 20px;
			}

			.review-person {
				font-family: InterBold;
				font-size: 16px;
				font-weight: 700;
				line-height: 22.4px;
				text-align: left;
				color: #151a20;
			}

			.review-agency {
				font-family: Inter;
				font-size: 14px;
				font-weight: 400;
				line-height: 19.6px;
				text-align: left;
				color: #151a20;
			}
		}

		.review-card:hover {
			background-color: #fc704c;

			.quote {
				color: white;
			}

			.review-text {
				color: white;
			}

			.review-person {
				color: white;
			}

			.review-agency {
				color: white;
			}
		}

		.card-active {
			background-color: #fc704c;

			.quote {
				color: white;
			}

			.review-text {
				color: white;
			}

			.review-person {
				color: white;
			}

			.review-agency {
				color: white;
			}
		}
	}

	.dots {
		margin-top: 30px;

		.dot {
			width: 12px;
			height: 12px;
			border-radius: 6px;
			background-color: #ced5de;
		}

		.active {
			width: 16px;
			height: 16px;
			border-radius: 8px;
			background-color: #550856;
		}
	}
}

.check-availability-page {
	.cover-block {
		width: 100%;
		height: 100%;
		position: relative;

		.ant-input {
			border: 1px solid rgb(206, 213, 222);
			border-radius: 6px;
			box-shadow: none;
		}

		.ant-dropdown-trigger {
			box-shadow: none !important;
		}

		.ant-dropdown-trigger .ant-dropdown-open {
			box-shadow: none !important;
		}

		.page-title {
			font-family: "Nunito Sans";
			font-style: normal;
			font-weight: 700;
			font-size: 36px;
			line-height: 47px;
			text-align: center;
			color: #ffff;
		}

		.page-subtitle {
			font-family: "Nunito Sans";
			font-style: normal;
			font-weight: 500;
			font-size: 20px;
			line-height: 28px;
			color: #c7afc8;

			@media (max-width: 769px) {
				text-align: center;
			}
		}

		.form-parent {
			width: 100%;
			background: #ffff;
			border-radius: 16px;
			padding: 40px;
			gap: 24px;
			border-radius: 16px;
			margin-top: 36px;

			.space-style {
				@media (max-width: 769px) {
					padding-bottom: 15px;
				}
			}

			form {
				margin: 0 auto;

				.form-input-block {
					padding-bottom: 5px;
				}

				.form-email-style {
					padding-left: 38px;
				}

				label {
					font-size: 14px;
					line-height: 19px;
					margin: 5px 0 5px;
				}

				.form-group {
					margin-bottom: unset;
					position: relative;

					img {
						position: absolute;
						left: 14px;
						top: 58px;
					}
				}

				input {
					border: 1px solid #8f99b4;
					border-radius: 6px;
					height: 40px;
				}
			}
		}

		.red-required {
			color: #fc704c;
		}

		.short-footer {
			bottom: 0px;
			width: 100%;
			padding: 16px;
			font-size: 14px;
			line-height: 19px;
			margin-top: 70px;
			gap: 8px;
			border-top: 2px solid #5b115c;

			p {
				margin-bottom: unset;
				color: #ffff;
			}

			a {
				font-weight: 600px;
				font-size: 12px;
				line-height: 17px;
				color: #fc704c;
			}

			@media (min-width: 320px) and (max-width: 512px) {
				// flex-direction: column;
				// align-items: center;
				flex-wrap: wrap;
				padding: 16px 0px;
				gap: 6px;

				p {
					display: flex;
					// flex-direction: column;
					align-items: center;
				}

				.p-cont {
					margin-left: 4px;
				}
			}
		}
	}
}

.success-modals {
	border-radius: 10px;

	.modal-content {
		width: 430px;
		padding: 32px 20px;
		gap: 32px;
		border-radius: 16px;
		background: var(--Immo-Belgium-White, #fff);
		box-shadow: 0px 4px 18px 0px rgba(29, 46, 91, 0.3);
	}
}

.success-modal-1 {
	margin-top: -15px;

	.modal-body {
		padding: 0px;
	}

	.intro-section {
		color: #151a20;
		font-family: "Nunito Sans";
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 23px;
	}

	.options-style {
		font-family: "Nunito Sans";
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 18px;
	}

	.error-message {
		color: red;
	}

	.text-style {
		color: #000501;
		font-family: "Nunito Sans";
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 23px;
		text-align: center;
	}

	.input-form {
		display: flex;
		padding: 12px 16px;
		align-items: center;
		flex: 1 0 0;
		border: #8f99b4;
		border-radius: 6px;
	}

	.footer-container {
		@media (max-width: 480px) {
			display: none;
		}
	}

	.button-continue {
		width: 157px;
		padding: 14px 16px;
		justify-content: center !important;
		align-items: center;
		gap: 6px;
		border: none;
		border-radius: 8px;
		background: #fc704c;
		color: #ffffff;
		font-size: 16px;
		font-weight: 600;
		line-height: 20px;
	}

	.button-continue:active {
		width: 157px;
		padding: 14px 16px;
		justify-content: center !important;
		align-items: center;
		gap: 6px;
		border: none;
		border-radius: 8px;
		background: #fc704c;
		color: #ffffff;
		font-size: 16px;
		font-weight: 600;
		line-height: 20px;
	}

	.form-button:focus {
		border: none !important;
	}
}

.success-modal-title {
	color: #151a20;
	font-family: "Nunito Sans";
	font-size: 28px;
	font-style: normal;
	font-weight: 700;
	line-height: 34px;
	text-align: center;
}
