.ant-breadcrumb {
	.ant-breadcrumb-link {
		a {
			color: var(--colorBlue) !important;
		}
	}
}
.estimate-home-searchbar {
	max-width: 400px;
	@media (min-width: 768px) {
		max-width: 250px;
	}
}

.third-dd {
	.dropdown-toggle {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		padding-right: 50px;
	}
	.dropdown-item {
		white-space: normal;
		line-height: 17px;
	}
}

.input-with-label-block {
	margin-bottom: 30px;
	.label-block {
		width: 100%;
		@media (min-width: 768px) {
			width: 50%;
			padding-right: 40px;
		}
	}

	.input-block {
		width: 100%;
		@media (min-width: 768px) {
			width: 50%;
		}
	}

	label {
		font-size: 16px !important;
	}
}

.estimate-home-rating-block {
	padding-bottom: 30px;
	@media (min-width: 768px) and (max-width: 1023px) {
		padding-left: 60px;
	}

	@media (min-width: 1024px) and (max-width: 1200px) {
		padding-left: 70px;
		padding-bottom: 30px;
	}

	@media (min-width: 1201px) {
		padding-left: 80px;
		padding-bottom: 50px;
	}
}

.main-page {
	background-color: var(--colorWhite);
	color: var(--mainColor);

	.autocomplete-list {
		position: absolute;
		background-color: var(--colorWhite);
		z-index: 20;
		list-style-type: none;
		padding: unset;
		box-shadow: 0 4px 12px rgba(56, 114, 239, 0.3);

		li {
			padding: 10px;
			cursor: pointer;
			text-overflow: ellipsis;
			white-space: nowrap;
			max-width: 360px;
			overflow: hidden;

			&:hover {
				background-color: var(--bg-blue);
			}
		}
	}

	.image-carousel {
		// max-width: 1400px;
		margin: 0 auto;
		position: relative;

		&__popup {
			padding: 50px;
			display: flex;
			flex-direction: column;
			align-items: center;

			width: 480px;

			position: absolute;
			z-index: 5;
			left: 136px;
			top: 180px;

			background: var(--colorWhite);
			box-shadow: 0 4px 42px rgba(29, 46, 91, 0.05);
			border-radius: 10px;

			.custom-autocomplete {
				input {
					padding: 12px 14px;
					height: unset;
					width: 100%;
					border: 1px solid var(--colorGray);

					&::placeholder {
						color: var(--colorGray);
					}

					:active {
						border: 1px solid var(--colorGray);
					}
				}
			}

			h1 {
				color: var(--colorBlack);
				font-family: var(--fontNunitoBold);
				font-size: 36px;
				line-height: 105%;
				max-width: 360px;
				margin-bottom: 20px;
				align-self: baseline;
			}

			input {
				border-radius: 6px;
				height: 47px;
			}

			button {
				font-family: var(--fontNunitoBold);
				font-size: 16px;
				line-height: 22px;
				margin: 20px 0 0;
				width: 100%;
				height: 47px;
				background: var(--colorBlue);
				border-radius: 8px;
			}
		}

		img {
			width: 100%;
			height: 717px;
			// border-radius: 10px;
		}
	}

	.info-block {
		margin-top: 170px;

		&__top,
		&__middle {
			padding: 0 20px;
		}

		&__middle {
			margin-top: 120px;
		}

		// .map-image {
		// 	width: 536px;
		// 	height: 438px;
		// }

		// .logo-gray {
		// 	width: 211px;
		// 	height: 33px;
		// }

		&__bottom {
			margin-top: 64px;
			display: flex;
			flex-direction: column;
			align-items: center;

			font-family: var(--fontNunitoBold);

			span {
				font-size: 20px;
				line-height: 27px;
				color: var(--colorGray);
				margin-bottom: 20px;
				display: block;
			}

			p {
				font-family: var(--fontNunitoBold);
				font-style: italic;
				font-size: 24px;
				font-weight: 700;
				color: #1d2e5b;
				line-height: 32.74px !important;
				text-align: center;
			}
		}

		.get-estimation {
			font-family: var(--fontNunitoBold);
			width: 240px;
			height: 50px;

			background: var(--colorBlue);
			border-radius: 8px;
		}

		.title-h2 {
			font-family: var(--fontNunitoBold);
			font-size: 1.5rem;
			line-height: 33px;
			width: 60%;
			max-width: 427px;
			margin: 20px 0;
		}

		p {
			width: 80%;
			max-width: 427px;
			line-height: 22px;
			margin-bottom: 30px;
		}

		.left-block {
			width: 50%;
		}

		.right-block {
			@media (min-width: 768px) and (max-width: 1023px) {
				margin-left: 50px;
			}
			.image-block {
				.mr-16px {
					margin-right: 16px;
				}

				.first-image {
					width: 172px;
					height: 418px;
				}

				.second-image {
					width: 172px;
					height: 206px;
				}

				.third-image {
					width: 172px;
					height: 186px;
				}

				.fourth-image {
					width: 127px;
					height: 216px;
				}

				.fifth-image {
					width: 218px;
					height: 236px;
				}

				&__left {
					img {
						margin-top: 20px;
					}
				}

				&__right {
					.top {
						display: flex;
						align-items: baseline;
						margin-bottom: 16px;
					}

					.bottom {
						display: flex;
						align-items: flex-start;
					}
				}
			}
		}
	}

	.testimonials {
		margin-top: 73px;

		h3 {
			font-family: var(--fontNunitoBold);
			text-align: center;
			line-height: 33px;
			margin-bottom: 18px;
		}

		&__list {
			display: grid;
			grid-template-columns: 1fr 1fr 1fr;
			grid-gap: 30px;

			.load-more {
				margin-bottom: 107px;
				grid-column-start: 2;
				margin-top: unset;
				font-size: 14px;
				display: flex;
				justify-content: center;
				align-items: center;
				width: 356px;
				height: 47px;
				color: var(--colorBlue);
				background: var(--bg-blue);
				border-radius: 8px;
				border: unset;

				img {
					margin-right: 6px;
				}
			}
		}

		&__item {
			width: 356px;
			padding: 20px;

			background: var(--colorWhite);
			border: 1px solid rgba(56, 113, 239, 0.2);
			box-sizing: border-box;
			border-radius: 8px;

			.avatar {
				width: 42px;
				height: 42px;
				margin-right: 12px;
			}

			.full-name {
				font-family: var(--fontNunitoBold);
				font-size: 16px;
				line-height: 22px;
			}

			.dv-star-rating {
				label {
					width: 14px;
					height: 14px;
					margin-bottom: unset;
					margin-right: 1px;
					float: unset !important;
				}
			}

			.desc {
				font-size: 14px;
				line-height: 19px;
			}
		}
	}

	.short-footer {
		padding: 0 40px 25px;
		font-size: 14px;
		line-height: 19px;

		.link {
			cursor: pointer;
			color: var(--colorBlue);
		}
	}

	.steps-block {
		&__progress-bar {
			width: 100%;
			height: 4px;
			background: #e5eaf7;

			.progress-bar {
				-webkit-transition: width 0.5s ease;
				-o-transition: width 0.5s ease;
				transition: width 0.5s ease;
				background: var(--colorBlue);
			}
		}

		&__main {
			min-height: calc(100vh - var(--headerHeight));

			.step-title {
				display: block;
				font-size: 14px;
				line-height: 19px;
				margin-bottom: 5px;
			}

			h4 {
				font-family: var(--fontNunitoBold);
				line-height: 33px;
				margin-bottom: 30px;
			}

			.custom-select {
				background-image: url("../../assets/images/arrow.svg");
				background-size: 18px;

				&__value-container {
					width: 100%;
					height: 46px;
					position: unset;
				}

				&__indicators {
					display: none;
				}
			}

			#locality,
			#street {
				width: 310px;

				.input-group {
					div:nth-child(1) {
						width: 100%;
					}
				}
			}

			.input-group-text {
				border-color: var(--colorGray);
				background: var(--colorWhite);

				img {
					cursor: pointer;
				}
			}

			#number,
			#zip {
				width: 96px;
			}

			.form-row {
				margin: unset;
				justify-content: space-between;
			}

			.steps-btn-group {
				// padding-bottom: 54px;
				flex-direction: row-reverse;
				font-family: var(--fontNunitoBold);

				.prev-step {
					height: 50px;
					color: var(--colorBlue);
					background: var(--colorWhite);
					border: unset;

					img {
						margin-right: 8px;
					}
				}

				.next-step {
					width: 218px;
					height: 50px;
					background: var(--colorBlue);
					border-radius: 8px;
					margin-bottom: 20px;
				}
			}
		}

		.step-one,
		.step-two,
		.step-three,
		.step-four {
			.custom-styles {
				position: relative;

				.error {
					font-size: 14px;
					position: absolute;
					width: max-content;
					color: red;
				}
			}

			label {
				font-size: 14px;
				line-height: 19px;
				margin-bottom: 5px;
			}

			input:read-only {
				&::-webkit-outer-spin-button,
				&::-webkit-inner-spin-button {
					-webkit-appearance: none;
					margin: 0;
				}
			}

			input:not([type="checkbox"]) {
				width: 270px;
				height: 46px;
				border-color: var(--colorGray);

				&:focus {
					box-shadow: unset;
				}
			}
		}

		.step-one,
		.step-two,
		.step-three,
		.final-step,
		.step-four,
		.create-personal-account {
			width: 100%;
			max-width: 450px;
			height: 100%;
		}

		.step-one {
			h5 {
				font-family: var(--fontNunitoBold);
				font-size: 18px;
				line-height: 25px;
				margin: 15px 0 0;
			}

			button {
				margin-top: 20px;
				// width: 433px;
				height: 50px;
				background: var(--colorBlue);
				border-radius: 8px;
				margin-bottom: 20px;
				// position: absolute;
				// bottom: 0px;
			}

			.properties {
				margin: 20px 0;

				.property-home,
				.property-apartment,
				.property-land {
					position: relative;
					//width: 131px;
					width: 48%;
					height: 122px;
					border: 1px solid var(--colorLightGrey);
					box-sizing: border-box;
					border-radius: 8px;

					display: flex;
					align-items: center;
					justify-content: center;
					flex-direction: column;

					.title {
						display: block;
						margin-top: 10px;
						line-height: 22px;
					}

					.active-item {
						background: url("../../assets/images/active-item.svg") no-repeat
							center 32%;
						position: absolute;
						right: -18px;
						top: -15px;
						box-shadow: 0 4px 12px rgba(56, 114, 239, 0.3);
						border-radius: 20px;
						background-size: inherit;
						width: 34px;
						height: 34px;
						display: none;
					}
				}

				.active {
					border: 1px solid var(--colorBlue);

					.active-item {
						display: block;
					}
				}
			}
		}

		.step-two,
		.step-three,
		.step-four {
			h4 {
				margin-bottom: 30px;

				span {
					text-transform: capitalize;
				}
			}

			.range {
				background: var(--bg-blue);
				border-radius: 6px;
				height: 59px;

				input {
					width: 261px;
				}

				.prepend,
				.append {
					span {
						font-size: 14px;
						line-height: 19px;
						border: unset;
						background-color: transparent;
					}
				}
			}

			.construction-year-label,
			.renovation-year-label,
			.renovation-level-label {
				width: 100%;
			}

			.constructionYear,
			.renovationYear {
				border-radius: 6px !important;
			}

			.bg-block {
				margin-bottom: 21px;
			}

			.number-floors,
			.bg-block {
				padding: 10px 10px 10px 20px;
				background: var(--bg-blue);
				border-radius: 6px;

				.input-block {
					width: 207px !important;
				}
			}

			.input-group {
				margin-bottom: 30px;
				align-items: center;
				justify-content: space-between;
				flex-wrap: nowrap;

				label {
					line-height: 22px;
					font-size: 16px;
					display: flex;

					.custom-label {
						width: 70%;
					}
				}

				// .number-levels {
				// min-width: 217px;
				// appearance: none;
				// }

				// .inside-floors {
				// min-width: 207px;
				// }

				.terras-value-label {
					width: 40%;

					.tooltip-info {
						margin-left: 5px;
						position: unset;
					}
				}

				.input-block {
					display: flex;
					width: 220px;

					.input-group-text {
						justify-content: center;
						padding: unset;
						width: 45px;
						height: 46px;
						background: var(--bg-blue);
					}

					.input-group-append {
						.input-group-text {
							border-left: unset;
							border-radius: 0 6px 6px 0;
						}
					}

					.input-group-prepend {
						.input-group-text {
							border-right: unset;
							border-radius: 6px 0 0 6px;
						}
					}

					input {
						border-radius: 8px 0 0 8px;
						width: 100%;
					}
				}

				.input-border-radius-0 {
					span {
						cursor: pointer;
					}

					input {
						background-color: var(--colorWhite);
						text-align: center;
						border-radius: 0;
						border-left: unset;
					}
				}
			}

			input[type="range"] {
				// -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
				background: var(--colorLightBlue);
				border-color: var(--colorLightBlue);
				border-radius: 4px;
				height: 3px;
			}

			input[type="range"]::-webkit-slider-thumb {
				// -webkit-appearance: none;
			}

			input[type="range"]:focus {
				outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
			}

			input[type="range"]::-ms-track {
				width: 100%;
				cursor: pointer;

				/* Hides the slider so custom styles can be added */
				background: transparent;
				border-color: transparent;
				color: transparent;
			}

			input[type="range"]::-webkit-slider-thumb {
				-webkit-appearance: none;
				cursor: pointer;
				width: 19px;
				height: 19px;
				background: var(--colorBlue);
				box-shadow: 0 4px 10px rgba(56, 114, 239, 0.15);
				border-radius: 10px;
			}

			/* All the same stuff for Firefox */
			input[type="range"]::-moz-range-thumb {
				cursor: pointer;
				width: 19px;
				height: 19px;
				background: var(--colorBlue);
				box-shadow: 0 4px 10px rgba(56, 114, 239, 0.15);
				border-radius: 10px;
			}

			/* All the same stuff for IE */
			input[type="range"]::-ms-thumb {
				cursor: pointer;
				width: 19px;
				height: 19px;
				background: var(--colorBlue);
				box-shadow: 0 4px 10px rgba(56, 114, 239, 0.15);
				border-radius: 10px;
			}

			.block-with-tooltip {
				width: 140px !important;
			}

			.tooltip-info {
				position: absolute;
				right: -15px;
				margin-right: unset;
				top: 0;
			}

			img {
				margin-right: 12px;
			}
		}

		.step-four {
			.input-group {
				margin-bottom: 20px;
				height: 46px;
			}

			.epc {
				.input-group-append {
					.input-group-text {
						font-size: 14px;
						line-height: 19px;
						width: 79px !important;
					}
				}
			}

			.custom-btn-group {
				grid-template-columns: repeat(4, 1fr) !important;
			}

			.terras-block {
				position: relative;
				width: 217px;
				height: 161px;

				.north {
					width: 59px;
					height: 41px;

					left: 78.61px;
					top: 0;
				}

				.north-east {
					width: 56px;
					height: 56px;

					left: 125.53px;
					top: 7px;
				}

				.east {
					width: 41px;
					height: 59px;

					left: 147.33px;
					top: 52.15px;
				}

				.south-east {
					width: 56px;
					height: 56px;

					left: 125.34px;
					bottom: 6.41px;
				}

				.south {
					width: 59px;
					height: 41px;

					left: 78.32px;
					bottom: 0;
				}

				.south-west {
					width: 56px;
					height: 56px;

					left: 34.81px;
					bottom: 6.88px;
				}

				.west {
					width: 41px;
					height: 59px;

					left: 27.53px;
					top: 49.87px;
				}

				.north-west {
					width: 56px;
					height: 56px;

					left: 35.4px;
					top: 6.33px;
				}

				img {
					cursor: pointer;
					position: absolute;
					margin-right: unset;
				}
			}

			// .steps-btn-group {
			// 	margin-bottom: 72px;
			// }
		}

		.step-three {
			.dropdown {
				width: 217px;
				height: 46px;

				button {
					width: 100%;
					height: 46px;
					text-align: inherit;
					background: transparent;
					color: #1d2e5b;

					&:after {
						background-image: url("../../assets/images/arrow.svg");
						background-size: contain;
						width: 18px;
						height: 18px;
						display: block;
						position: absolute;
						right: 12px;
						top: 15px;
						border: unset;
					}
				}

				.active {
					color: var(--mainColor);
					background: var(--bg-blue);

					img {
						display: block !important;
					}
				}

				.dropdown-menu {
					overflow: auto;
					height: 200px;
					width: 100%;
					padding: 10px;
					box-shadow: 0 10px 24px rgba(29, 46, 91, 0.24);
					border-radius: 6px;

					a {
						display: flex;
						align-items: center;
						justify-content: space-between;

						padding: 12px 14px;
						height: 46px;
						border-radius: 6px;

						&:hover {
							background: var(--bg-blue);
						}

						img {
							display: none;
						}
					}
				}
			}
		}

		.step-three,
		.step-four {
			display: flex;
			.form-label {
				font-size: 16px;
				line-height: 22px;
				width: 70%;
				white-space: pre-wrap;
			}

			.optional {
				font-size: 16px;
				line-height: 22px;
				color: var(--colorGrayTwo);
				font-family: var(--fontNunito);
			}

			.group-block {
				margin-bottom: 30px;

				.first-btn {
					border-radius: 6px 0 0 6px;
				}

				.last-btn {
					border-radius: 0 6px 6px 0;
				}

				.custom-btn-group {
					margin-top: 5px;
					display: grid;
					grid-template-columns: 1fr 1fr 1fr;

					span {
						line-height: 22px;
						display: block;
						text-align: center;
						margin-bottom: 16px;
					}

					.custom-active {
						background: var(--mainColor);
						color: var(--colorWhite);
					}

					button {
						height: 46px;
						font-size: 14px;
						line-height: 19px;

						color: var(--mainColor);
						background-color: transparent;
						border-color: var(--colorLightGrey);

						&:active,
						&:focus,
						&:target {
							box-shadow: none;
							background: var(--mainColor);
							color: var(--colorWhite);
						}
					}
				}
			}

			.form-group {
				display: flex;
				justify-content: space-between;
				align-items: center;

				select {
					width: 217px;
					height: 46px;
				}
			}

			.custom-select {
				background-image: url("../../assets/images/arrow.svg");
				background-size: 18px;
			}
		}

		.create-personal-account {
			h4 {
				margin-bottom: 5px;
			}

			.form-label {
				font-size: 16px;
				line-height: 22px;
			}

			.have-account {
				margin: 15px 0 30px;
				color: var(--colorBlue);
				cursor: pointer;
				display: flex;

				img {
					margin-left: 3px;
					transform: rotate(270deg);
				}
			}

			&__main-block {
				.title-block {
					display: flex;

					color: var(--colorGrayTwo);
					font-family: var(--fontNunitoBold);
					font-size: 16px;
					line-height: 22px;
					margin-bottom: 30px;

					span {
						text-align: center;
						height: 36px;
						width: 50%;
						cursor: pointer;
						border-bottom: 1px solid #d2d5de;
					}

					.active {
						color: var(--mainColor);
						border-bottom: 2px solid var(--colorBlue);
					}
				}

				.switch-block {
					display: flex;
					justify-content: space-between;
					margin-bottom: 30px;

					.homeowner-block,
					.not-owner-block,
					.professional-account {
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;
						cursor: pointer;
						position: relative;

						width: 206.5px;
						height: 122px;
						border: 1px solid var(--colorLightGrey);
						box-sizing: border-box;
						border-radius: 8px;

						img {
							margin-bottom: 10px;
						}

						.active-item {
							background: url("../../assets/images/active-item.svg") no-repeat
								center 32%;
							position: absolute;
							right: -18px;
							top: -15px;
							box-shadow: 0 4px 12px rgba(56, 114, 239, 0.3);
							border-radius: 20px;
							background-size: inherit;
							width: 34px;
							height: 34px;
							display: none;
						}
					}

					.active-block {
						border: 1px solid var(--colorBlue);

						.active-item {
							display: block;
						}
					}
				}

				.professional {
					display: grid;
					grid-template-columns: repeat(3, 1fr);
					grid-gap: 20px;

					.professional-account {
						width: 131px;
						height: 119px;
					}

					.other {
						height: 59px;
						width: 100%;
						grid-column-start: 1;
						grid-column-end: 4;
					}
				}
			}

			.form-group {
				margin-bottom: 30px;
			}

			label {
				font-size: 14px;
				line-height: 19px;
				margin-bottom: 5px;
			}

			.dropdown {
				margin-bottom: 30px;

				button {
					width: 100%;
					height: 46px;
					text-align: inherit;
					background: transparent;
					color: #1d2e5b;
					border-color: #8f99b4 !important;

					&:after {
						background-image: url("../../assets/images/arrow.svg");
						background-size: contain;
						width: 18px;
						height: 18px;
						display: block;
						position: absolute;
						right: 12px;
						top: 15px;
						border: unset;
					}
				}

				.active {
					color: var(--mainColor);
					background: var(--bg-blue);

					img {
						display: block !important;
					}
				}

				.dropdown-menu {
					width: 100%;
					padding: 10px;
					box-shadow: 0 10px 24px rgba(29, 46, 91, 0.24);
					border-radius: 6px;

					a {
						display: flex;
						align-items: center;
						justify-content: space-between;

						padding: 12px 14px;
						height: 46px;
						border-radius: 6px;

						&:hover {
							background: var(--bg-blue);
						}

						img {
							display: none;
						}
					}
				}
			}

			.label {
				font-size: 14px;
				line-height: 19px;
				margin-bottom: 4px;
			}

			.show {
				button {
					&:after {
						background-image: url("../../assets/images/arrow.svg");
						transform: rotate(180deg);
					}
				}
			}
		}

		.final-step {
			font-size: 14px;
			line-height: 19px;

			.step-desc {
				display: block;
				margin-bottom: 20px;
			}

			.was-validated {
				.form-control:valid {
					background-image: url("../../assets/images/valid.svg");
				}
			}

			.fs-16 {
				font-size: 16px;
				line-height: 22px;
				margin-bottom: 30px;
			}

			.have-account {
				display: block;
				cursor: pointer;
				color: var(--colorBlue);
				margin-bottom: 30px;

				img {
					transform: rotate(270deg);
				}
			}

			label {
				margin-bottom: 5px;
			}

			input {
				border: 1px solid var(--colorGray);
				border-radius: 6px;
			}

			.form-row {
				flex-wrap: unset;
				align-items: flex-start;

				.form-group {
					max-width: 205px;

					input {
						width: 205px;
					}
				}
			}

			.form-group {
				margin-bottom: unset;

				input {
					height: 46px;
				}
			}

			.recommendation {
				display: block;
				font-size: 12px;
				line-height: 16px;
				margin: 12px 0 30px;
			}

			.form-check {
				font-size: 16px;
				line-height: 22px;
				margin-bottom: 10px;
			}
		}

		.switch {
			position: relative;
			display: inline-block;
			width: 64px;
			height: 36px;
		}

		/* Hide default HTML checkbox */
		.switch input {
			opacity: 0;
			width: 0;
			height: 0;
		}

		/* The slider */
		.slider {
			position: absolute;
			cursor: pointer;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-color: rgba(56, 113, 239, 0.1);
			-webkit-transition: 0.4s;
			transition: 0.4s;
		}

		.slider:before {
			position: absolute;
			content: "";
			height: 28px;
			width: 28px;
			left: 4px;
			bottom: 4px;
			background-color: white;
			-webkit-transition: 0.4s;
			transition: 0.4s;
		}

		input:checked + .slider {
			background-color: var(--colorBlue);
		}

		input:focus + .slider {
			box-shadow: 0 0 1px var(--colorBlue);
		}

		input:checked + .slider:before {
			-webkit-transform: translateX(26px);
			-ms-transform: translateX(26px);
			transform: translateX(26px);
		}

		/* Rounded sliders */
		.slider.round {
			border-radius: 40px;
		}

		.slider.round:before {
			border-radius: 50%;
		}
	}

	@media (min-width: 600px) and (max-width: 768px) {
		.info-block {
			margin-top: 250px;
		}
	}

	@media (min-width: 768px) and (max-width: 1439px) {
		.main-page {
			width: fit-content;
			background-color: var(--colorWhite);
			color: var(--mainColor);
		}

		.image-carousel {
			img {
				height: 330px;
				// border-radius: 10px;
				object-fit: cover;
			}

			&__popup {
				box-shadow: 0 4px 12px rgba(56, 114, 239, 0.3);
				top: 180px;
			}
		}

		.info-block {
			margin-top: 240px;
			max-width: unset;
			padding: unset;

			.title-h2 {
				width: 80%;
			}

			// &__top {
			// 	.first-image-mobile,
			// 	.second-image-mobile {
			// 		margin-right: 10px;
			// 		width: 108px;
			// 	}

			// .second-image-mobile {
			// 	height: 245px;
			// }

			// .first-image-mobile,
			// .third-image-mobile {
			// 	height: 213px;
			// }
			// }

			// &__middle {
			// 	.left-block {
			// 		margin-right: 20px;
			// 	}

			// 	.map-image {
			// 		width: 342px;
			// 		height: 279px;
			// 	}
			// }
		}

		.testimonials {
			&__list {
				grid-template-columns: 1fr 1fr;
				grid-gap: 20px;

				.load-more {
					grid-column-start: 1;
					grid-column-end: 3;
					justify-self: center;
				}
			}
			&__item {
				width: 342px;
			}

			.desc {
				display: block;
				margin-top: 20px;
			}
		}
	}

	@media (min-width: 320px) and (max-width: 767px) {
		.main-page {
			width: fit-content;
			background-color: var(--colorWhite);
			color: var(--mainColor);
		}

		.create-personal-account {
			.title-in-mobile {
				margin-top: 60px;
			}
		}

		.main-page .steps-block__main #number,
		.main-page .steps-block__main #zip {
			width: 150px;
		}
		.image-carousel {
			img {
				border-radius: unset;
				width: 100%;
				height: 330px;
			}

			&__popup {
				left: calc(50% - 172px);
				top: 50%;
				padding: 30px;
				width: 343px;

				h1 {
					font-size: 24px;
					line-height: 33px;
					text-align: center;
					width: 100%;
				}
			}
		}

		.info-block {
			margin-top: 280px;

			.first-image-mobile {
				width: 108px;
				height: 213px;
			}

			.second-image-mobile {
				width: 108px;
				height: 245px;
			}

			.third-image-mobile {
				width: 108px;
				height: 213px;
			}

			.map-image {
				width: 343px;
				height: 280px;
			}

			.logo-gray {
				width: 150px;
				height: 24px;
			}

			.title-h2 {
				font-size: 20px;
				line-height: 27px;
				text-align: center;
				width: 70%;
				margin: 30px auto 20px;
			}

			p {
				width: 100%;
				text-align: center;
				margin: 0 auto 30px;
			}

			button {
				display: block;
				margin: 0 auto;
			}

			&__top {
				padding: unset;
				flex-direction: column-reverse;

				.mt-30px {
					margin-top: 30px;
				}

				.left-block {
					width: 100%;
				}

				.right-block {
					.image-block {
						align-items: flex-start;
						justify-content: space-between;
					}
				}
			}

			&__middle {
				margin-top: 80px;
				padding: unset;
				flex-direction: column;

				.left-block {
					width: 100% !important;
					display: flex;
					justify-content: center;
				}
			}

			&__bottom {
				margin-top: 80px;

				p {
					font-size: 20px;
					line-height: 27px;
				}

				img {
					width: 160px;
					height: 32px;
				}
			}
		}

		.testimonials {
			margin-top: 80px;

			&__list {
				grid-template-columns: 1fr;
				grid-gap: 20px;

				.load-more {
					margin-bottom: 80px;
					grid-column-start: 1;
					width: auto;
				}
			}

			&__item {
				width: 100%;
			}
		}

		.short-footer {
			flex-direction: column;
			align-items: center;

			p {
				display: flex;
				flex-direction: column;
				align-items: center;
			}
		}

		.steps-block {
			&__main {
			}

			.ml-156 {
				margin-left: unset;
				margin-top: unset;
				width: 100% !important;
			}

			.step-one,
			.step-two,
			.step-three,
			.step-four,
			.create-personal-account,
			.final-step {
				margin-top: 87px;
				// width: unset;
				padding: 0 16px;

				.step-title {
					display: none;
				}

				h4 {
					text-align: center;
				}

				#locality,
				#street {
					width: 225px;
				}

				.custom-label {
					width: 69% !important;
				}

				.number-levels {
					min-width: 132px !important;
					// width: 132px !important;
				}

				.construction-year-label,
				.renovation-year-label,
				.renovation-level-label {
					width: 55%;
				}

				.number-floors {
					.input-block {
						min-width: 132px;
					}
				}

				.mr-4 {
					margin-right: unset !important;
				}

				.properties {
					flex-direction: column;

					.property-home,
					.property-apartment,
					.property-land {
						width: 100%;
						height: 76px;
						margin-bottom: 10px;
						flex-direction: row;
						justify-content: flex-start;

						img {
							width: 36px;
							height: 36px;
							margin-left: 20px;
							margin-right: 10px;
						}

						.title {
							margin-top: unset;
						}

						.active-item {
							top: 21px;
							right: 21px;
						}
					}
				}

				.pick-on-map {
					display: flex;
					color: var(--colorBlue);
					font-size: 14px;
					line-height: 19px;

					img {
						margin-right: 6px;
					}
				}

				.mobile-map {
					position: fixed;
					top: 0;
					width: 100%;
					height: 100%;
					left: 0;

					.address-block {
						position: absolute;
						display: flex;
						justify-content: space-between;
						align-items: flex-start;
						padding: 30px;
						background-color: var(--colorWhite);
						z-index: 50;
						top: 0;
						left: 0;
						width: 100%;
						height: 104px;

						span {
							width: 90%;
							font-family: var(--fontNunitoBold);
							font-size: 16px;
							line-height: 22px;
						}
					}

					.close-map {
						font-family: var(--fontNunitoBold);
						color: var(--colorBlue);
						position: absolute;
						z-index: 50;
						bottom: 30px;
						width: 311px;
						height: 50px;
						left: 32px;
						background: var(--colorWhite);
						box-shadow: 0 4px 10px rgb(56 114 239 / 15%);
						border-radius: 8px;
						justify-content: center;
						align-items: center;
						display: flex;
					}
				}
			}

			.step-two,
			.step-three,
			.step-four,
			.create-personal-account,
			.final-step {
				.input-group {
					.input-block {
						width: 100%;
					}

					// .bedrooms,
					// .bathrooms,
					// .facades,
					// .block-with-tooltip {
					// 	// width: 128px;
					// }
				}

				.custom-width-40 {
					width: 40%;
				}

				.epc {
					align-items: flex-start;
					height: max-content;

					label {
						width: 50%;
					}
				}

				.number-floors {
					.input-block {
						width: 132px !important;
					}
				}

				.bg-block {
					.input-block {
						width: 156px !important;
					}
				}

				select {
					width: 166px !important;
				}

				.range {
					input {
						width: 50%;
					}
				}

				.steps-btn-group {
					flex-direction: column;

					// .next-step {
					// 	margin-bottom: 10px;
					// }

					.prev-step {
						margin-bottom: 28px;
					}
				}
			}

			.step-three,
			.step-four {
				h4 {
					display: flex;
					flex-direction: column;
				}

				.steps-btn-group {
					margin-bottom: unset;
				}
			}

			.create-personal-account,
			.final-step {
				margin-top: 30px;

				.step-title {
					display: block;
					text-align: center;
				}

				.have-account {
					justify-content: center;
				}

				.title-block {
					font-size: 14px;
					line-height: 19px;
				}

				.switch-block {
					margin-bottom: unset;
					flex-direction: column;

					.homeowner-block,
					.not-owner-block,
					.professional-account {
						width: 100%;
						height: 76px;
						margin-bottom: 20px;
						flex-direction: initial;
						justify-content: flex-start;

						img {
							width: 36px;
							height: 36px;
							margin: 0 10px 0 20px;
						}

						.active-item {
							top: 21px;
							right: 21px;
						}
					}
				}

				.professional {
					display: flex;
					grid-gap: unset;
					flex-direction: column;

					.professional-account {
						margin-bottom: 10px;
					}
				}

				.prev-step {
					margin-bottom: unset !important;
				}
			}

			.final-step {
				.have-account {
					text-align: center;
				}

				.form-row {
					.form-group {
						input {
							width: 161px;
						}
					}
				}
			}

			.next-step {
				width: 100% !important;
				margin-bottom: 28px;
			}
		}
	}
}

@media (min-width: 320px) and (max-width: 767px) {
	.main-page {
		// width: fit-content;
		background-color: var(--colorWhite);
		color: var(--mainColor);
	}

	.main-page .steps-block__main #number,
	.main-page .steps-block__main #zip {
		width: 150px;
	}
	.main-page .steps-block .step-one {
		height: inherit;
		margin-bottom: 50px;
	}
	.main-page .steps-block .step-two,
	.main-page .steps-block .step-three,
	.main-page .steps-block .final-step,
	.main-page .steps-block .step-four,
	.main-page .steps-block .create-personal-account {
		height: inherit;
		margin-bottom: 150px;
	}
	.botton-syicky {
		position: fixed;
		bottom: 0px;
		// width: 100%;
	}
	// .fixed-top {
	// 	position: relative;
	// 	top: 0;
	// 	right: 0;
	// 	left: 0;
	// 	z-index: 1030;
	// }
	// body {
	// 	padding-top: 0px;
	// }
	// .Header {
	// 	/* height: 60px; */
	// 	position: relative;
	// }
	// body {
	// 	padding-top: 0px;
	// }
}

@media screen and (device-width: 360px) and (device-height: 640px) and (-webkit-device-pixel-ratio: 2) {
	.main-page .steps-block .step-one .properties .property-home,
	.main-page .steps-block .step-one .properties .property-apartment,
	.main-page .steps-block .step-one .properties .property-land,
	.main-page .steps-block .step-two .properties .property-home,
	.main-page .steps-block .step-two .properties .property-apartment,
	.main-page .steps-block .step-two .properties .property-land,
	.main-page .steps-block .step-three .properties .property-home,
	.main-page .steps-block .step-three .properties .property-apartment,
	.main-page .steps-block .step-three .properties .property-land,
	.main-page .steps-block .step-four .properties .property-home,
	.main-page .steps-block .step-four .properties .property-apartment,
	.main-page .steps-block .step-four .properties .property-land,
	.main-page .steps-block .create-personal-account .properties .property-home,
	.main-page
		.steps-block
		.create-personal-account
		.properties
		.property-apartment,
	.main-page .steps-block .create-personal-account .properties .property-land,
	.main-page .steps-block .final-step .properties .property-home,
	.main-page .steps-block .final-step .properties .property-apartment,
	.main-page .steps-block .final-step .properties .property-land {
		.deviceWidth {
			width: 280px !important;
			padding: 0;
		}
		.center {
			text-align: center !important;
		}
		width: 100%;
		height: 76px;
		margin-bottom: 10px;
		-moz-box-orient: horizontal;
		-moz-box-direction: normal;
		flex-direction: row;
		-moz-box-pack: start;
		justify-content: flex-start;
	}
	.main-page .steps-block .step-one,
	.main-page .steps-block .step-two,
	.main-page .steps-block .step-three,
	.main-page .steps-block .final-step,
	.main-page .steps-block .step-four,
	.main-page .steps-block .create-personal-account {
		width: 433px;
		min-height: 10%;
	}
	.Header .step-info {
		position: absolute;
		top: 90px;
		left: -webkit-calc(15%);
		/* left: calc(50% - 124px); */
	}
}
