.Dashboard {
	@media (max-width: 768px) {
		width: 100% !important;
		padding: 0 !important;
		overflow-x: hidden;
	}

	.contact-popup {
		@media (max-width: 768px) {
			width: 100% !important;
			padding: 20px !important;
		}
	}
}

.request-free-estimation-modal {
	.ant-modal-content {
		border-radius: 10px;
	}

	.main-heading {
		font-family: "Nunito Sans";
		font-style: normal;
		font-weight: 700;
		font-size: 18px;
		line-height: 24px;
		text-align: center;
		color: #1d2e5b;
	}

	.sub-heading {
		font-family: "Nunito Sans";
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 18px;
		text-align: center;
		color: #1d2e5b;
	}

	.address-block {
		border: 1px solid #e5e5e5;
		border-radius: 8px;

		.address {
			font-family: "Nunito Sans";
			font-style: normal;
			font-weight: 700;
			font-size: 14px;
			line-height: 18px;
			color: #1d2e5b;
		}

		.property-info-block {
			gap: 20px;
		}
	}

	.error-block {
		background: #fff7f4;
		border: 1px solid #ef4a1e;
		border-radius: 6px;
		padding: 8px;
	}
}

.similar-properties-block {
	width: 100%;
	padding: 30px;
	margin-bottom: 0px;
	background: var(--colorWhite);
	border-radius: 0px;
	border-bottom: 1px solid #e5eaf7;

	@media (min-width: 768px) {
		margin-bottom: 20px;
		border-radius: 10px;
		border-bottom: none;
	}

	h2 {
		font-family: var(--fontNunitoBold);
		font-size: 20px;
	}

	.property-thumbnail {
		width: 100%;
		height: 180px;
		position: relative;
		border-radius: 2px;

		@media (min-width: 768px) {
			width: 240px;
		}
	}

	.house-type-text {
		font-size: 14px;
		font-weight: bold;
	}

	.address-text {
		font-family: "Nunito Sans";
		font-style: normal;
		font-weight: 700;
		font-size: 16px;
		line-height: 22px;
		color: #3871ef;
		margin-top: 6px;
		width: 100%;

		@media (min-width: 768px) {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		@media (min-width: 768px) and (max-width: 1023px) {
			width: 300px;
		}

		@media (min-width: 1024px) {
			width: 400px;
		}
	}

	.distance-text {
		font-family: "Nunito Sans";
		font-style: normal;
		font-weight: 400;
		font-size: 12px;
		line-height: 16px;
		color: #6c768f;
		margin-top: 6px;
	}

	.property-sale-method-text {
		font-family: "Nunito Sans";
		font-style: normal;
		font-weight: 400;
		font-size: 12px;
		line-height: 16px;
		color: #6c768f;
	}

	.property-agency-name {
		font-family: "Nunito Sans";
		font-style: normal;
		// font-weight: 400;
		font-size: 12px;
		line-height: 16px;
		color: #6c768f;
	}

	.property-private-owner {
		font-family: "Nunito Sans";
		font-style: normal;
		font-weight: 400;
		font-size: 12px;
		line-height: 16px;
		color: black;
	}

	.price-text {
		font-family: "Nunito Sans";
		font-style: normal;
		font-weight: 700;
		font-size: 18px;
		line-height: 25px;
	}

	.price-text-disabled {
		color: #8f99b4;
	}

	@media (max-width: 625px) {
		padding: 15px;
	}
}

.estimate-block-value-container {
	height: 80px;
	font-size: 12px;

	.portion-text {
		@media (min-width: 767px) {
			font-size: 14px;
			background-color: #f9faff;
		}
	}

	.minimum {
		@media (max-width: 767px) {
			background-color: #f9faff;
			height: 100%;
		}

		.price-text {
			font-size: 14px;

			@media (min-width: 767px) {
				font-size: 16px;
			}
		}
	}

	.average {
		@media (max-width: 767px) {
			background-color: #f2f6ff;
			height: 100%;
		}

		.price-text {
			font-size: 18px;
			color: var(--mainColor);

			@media (min-width: 767px) {
				font-size: 22px;
			}
		}
	}

	.maximum {
		@media (max-width: 767px) {
			background-color: #e5eaf7;
			height: 100%;
		}

		.price-text {
			font-size: 14px;

			@media (min-width: 767px) {
				font-size: 16px;
			}
		}
	}
}

.agency-section-tags {
	.tag-section {
		border: none;
		gap: 0px;
		font-size: 10px;
		padding: 0px;

		@media (min-width: 768px) {
			border: 1px solid #e5e5e5;
			border-radius: 4px;
			padding: 4px 8px;
			gap: 6px;
			font-size: 13px;
		}
	}
}

.market-report-section {
	border: 1px solid #e5eaf7;
	border-radius: 0px;
	border-bottom: none;
	margin-top: 20px;

	@media (min-width: 768px) {
		border-radius: 10px;
		border-bottom: 1px solid #e5eaf7;
		margin-top: 0px;
	}
}

.estimate-block-heading-main {
	font-size: 18px;
	line-height: 24px;
	font-weight: bold;
	color: var(--mainColor);
	font-family: var(--fontNunitoBold);

	@media (min-width: 768px) {
		font-size: 24px;
		line-height: 33px;
	}
}

// .dashboard-container {
// 	padding: 0px 20px;
// }

.top-navigator-dashboard {
	width: 100%;
	position: relative;

	// margin-top: 0px;
	@media (min-width: 768px) and (max-width: 1023px) {
		margin-top: -21px;
	}

	@media (max-width: 767px) {
		margin-top: -28px;
	}

	.background-hidder {
		background: #f2f6ff;
		position: absolute;
		height: 100px;
		width: 100%;
		bottom: 0px;
		z-index: -1;
	}

	.top-navigator-dashboard-container {
		width: 100%;
		bottom: 0px;
		height: 50px;
		background: white;
		border-radius: 8px;
		padding: 0px 5px;
		box-shadow: 0px 3px 10px #dfe4f0;

		@media (max-width: 1023px) {
			border-radius: 0px;
			height: 35px;
		}

		.nav-text {
			font-size: 12px;
			line-height: 16px;
			text-align: center;

			@media (min-width: 768px) {
				font-size: 14px;
				line-height: 18px;
			}
		}
	}
}

.sold-text {
	font-family: "Nunito Sans";
	font-style: normal;
	font-weight: 700;
	font-size: 10px;
	line-height: 14px;
	letter-spacing: 0.05em;
	color: #ef4a1e;
	border: 1px solid #ef4a1e;
	border-radius: 2px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 2px 4px;
	padding-top: 3px;
	width: min-content;
	margin-top: 8px;
}

.Dashboard {
	padding: 0px;

	@media (min-width: 1024px) {
		padding: 27px 0 80px;
	}

	color: var(--mainColor);

	h1 {
		font-family: var(--fontNunitoBold);
		line-height: 33px;
	}

	&__container {
		width: 100%;

		.main-info-block {
			padding: 30px;
			margin-bottom: 20px;
			width: 100%;

			background: var(--colorWhite);
			border-radius: 10px;

			.top-block {
				span {
					font-size: 14px;
					cursor: pointer;
					color: var(--colorBlue);

					img {
						margin-left: 4px;
					}
				}
			}

			.bottom-block {
				.image-block {
					margin-right: 30px;

					&__info {
						margin-left: 12px;

						.title {
							font-size: 12px;
							line-height: 16px;
							opacity: 0.7;
						}

						.desc {
							font-size: 14px;
						}
					}
				}
			}
		}

		.estimate-block {
			width: 100%;
			padding: 30px;
			margin-bottom: 20px;

			background: var(--colorWhite);
			border-radius: 10px;

			h2 {
				font-family: var(--fontNunitoBold);
			}

			@media (max-width: 625px) {
				padding: 0px !important;
				margin-bottom: 0px;
				border-radius: 0px;
				border-bottom: 1px solid #e5e5e5;
			}

			// p {
			// 	margin-bottom: 86px;
			// }

			.no-estim {
				margin-bottom: 20px;
			}

			.no-estimation-block {
				display: flex;
				align-items: flex-start;

				background: var(--bg-blue);
				border-radius: 8px;
				padding: 30px;

				img {
					margin-right: 10px;
				}

				span {
					color: var(--colorGrayTwo);
					width: 60%;
				}
			}

			.scale-block {
				.line {
					background-image: url("../../assets/images/price-graph.svg");
					width: 836px;
					background-size: cover;
					height: 35px;
				}

				.range {
					margin-top: 14px;

					.min,
					.max {
						display: flex;
						flex-direction: column;
					}

					.max {
						align-items: flex-end;
					}

					span {
						font-family: var(--fontNunitoBold);
						font-size: 16px;
					}

					.gray {
						font-family: var(--fontNunito);
						color: var(--colorGrayTwo);
						font-size: 10px;
						line-height: 16px;
						font-weight: 400;
					}
				}
			}

			.btn-block {
				// margin-top: 35px;
				font-family: var(--fontNunitoBold);

				span {
					line-height: 22px;
					display: block;
					text-align: center;
					// margin-bottom: 16px;
				}

				.custom-active {
					background: var(--mainColor);
					color: var(--colorWhite);
				}

				button {
					height: 43px;
					font-size: 14px;
					line-height: 19px;

					color: var(--mainColor);
					background-color: transparent;
					border-color: var(--colorLightGrey);

					&:active,
					&:focus,
					&:target {
						box-shadow: none;
						background: var(--mainColor);
						color: var(--colorWhite);
					}
				}
			}

			.estimation-popup {
				padding: 30px;
				margin-top: 20px;
				width: 100%;
				background: var(--bg-blue);
				border-radius: 8px;

				.ico {
					background-color: var(--colorWhite);
					border-right: unset;
					padding-left: 14px;
					padding-right: 0;
				}

				input {
					border-top-left-radius: 0 !important;
					border-bottom-left-radius: 0 !important;
				}

				.input-group-text {
					border-radius: 6px 0 0 6px;
				}

				input,
				textarea {
					box-shadow: unset;
					line-height: 22px;
					border-radius: 6px;

					&:focus {
						border-color: #ced4da;
					}
				}

				.border-left-0 {
					padding-left: 5px;
				}

				textarea {
					resize: none;
				}

				&__title {
					margin-bottom: 5px;
					font-size: 14px;
					line-height: 19px;
				}

				.input-group {
					margin-bottom: 20px;
				}

				.confirm {
					font-family: var(--fontNunitoBold);
					margin: 20px auto 0;
					display: block;

					width: 156px;
					height: 50px;

					background: var(--colorBlue);
					border-radius: 8px;
				}
			}

			.thanks-for-reply {
				margin-top: 20px;
				width: 100%;
				background: var(--colorWhite);

				border: 1px solid var(--colorLightBlue);
				box-sizing: border-box;
				border-radius: 8px;

				img {
					margin-top: 30px;
				}

				&__title {
					margin: 10px 0;
					font-family: var(--fontNunitoBold);
					font-size: 16px;
					line-height: 22px;
				}

				&__desc {
					font-size: 14px;
					line-height: 19px;
					margin-bottom: 30px;
				}

				button {
					font-family: var(--fontNunitoBold);
					width: 156px;
					height: 50px;
					background: var(--colorBlue);
					border-radius: 8px;
					margin-bottom: 30px;
				}
			}
		}

		.properties-block {
			padding: 20px 0 20px 20px;
			margin-bottom: 20px;

			background: var(--colorWhite);
			border-radius: 10px;

			.map-block {
				min-height: 400px;

				.gmnoprint,
				.gm-style-cc {
					display: none;
				}
			}

			.properties-list {
				width: 50%;

				h3 {
					font-family: var(--fontNunitoBold);
					line-height: 27px;
					margin: 20px 0 10px 20px;
				}

				p {
					font-size: 14px;
					line-height: 19px;
					margin: 0 0 24px 20px;
				}

				.property-main-block {
					// max-height: 1020px;
					position: relative;
					overflow-x: auto;
					padding-bottom: 5px;

					&__no-items {
						background: var(--bg-blue);
						font-size: 14px;
						line-height: 19px;
						border-radius: 8px;
						padding: 20px;
						margin: 0 20px;
						max-width: 450px;
						display: flex;
						align-items: self-start;

						img {
							margin-right: 10px;
						}

						span {
							color: var(--colorGrayTwo);
						}
					}
				}
			}
		}

		.find-agent-block {
			padding: 30px;

			background: var(--colorWhite);
			border-radius: 10px;

			.load-more {
				margin: 30px auto 0;
				width: 224px;
			}
		}

		.load-more {
			margin: 23px auto 0;
			font-size: 14px;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100%;
			max-width: 460px;
			height: 47px;
			color: var(--colorBlue);
			background: var(--bg-blue);
			border-radius: 8px;
			border: unset;

			img {
				margin-right: 6px;
			}

			&:hover,
			&:active {
				color: var(--colorBlue);
				background: var(--bg-blue);
			}
		}
	}

	@media (max-width: 767px) {
		&__container {
			margin-top: -10px !important;
		}
	}

	@media (max-width: 768px) {
		flex-direction: column;
		// padding-top: 40px;

		h1 {
			margin-bottom: 20px;
		}

		&__container {
			margin-top: 10px;
			justify-content: center;

			.main-info-block {
				.top-block {
					align-items: baseline !important;
				}
			}

			// .estimate-block {

			// 	.scale-block {
			// 		.line {
			// 			width: 100%;
			// 			background-image: url("../../assets/images/price-graph-tablet.svg");
			// 			background-size: cover;
			// 			background-repeat: no-repeat;
			// 			min-height: 50px;
			// 		}
			// 	}
			// 	.gray {
			// 		font-family: "Nunito Sans";
			// 		font-style: normal;
			// 		font-weight: 400;
			// 		font-size: 12px;
			// 		line-height: 16px;
			// 		color: #6c768f;
			// 	}
			// }

			.properties-block {
				position: relative;
				padding: 20px;
				flex-direction: column;

				.w-48 {
					width: 100% !important;
					margin-top: 86px;
					min-height: 482px;
				}

				.properties-list {
					h3 {
						// position: absolute;
						top: 10px;
					}

					p {
						// position: absolute;
						top: 60px;
					}

					.property-main-block {
						margin-top: 20px;

						.property-block {
							margin: 0 0 9px;

							&__info {
								position: relative;

								.address {
									margin-top: 32px;

									.property-number {
										display: none;
									}
								}

								.short-desc {
									.time {
										margin-bottom: 14px;
										flex-direction: row;

										a {
											margin-left: 4px;
										}
									}
								}
							}

							button {
								//  position: absolute;
								right: 0;
								bottom: 22px;
							}
						}
					}
				}
			}

			.find-agent-block {
				.agency-block {
					min-height: 170px;

					.short-info {
						flex-direction: column;
						align-items: baseline !important;

						.action-btn {
							top: 120px;
						}

						&__left {
							align-items: flex-start !important;
							margin-left: 10px;
						}

						&__right {
							margin: 12px 0 20px 10px;
						}

						.logo-block {
							// height: 126px;
							position: absolute;
							left: 0;
							width: 100%;
							border: 1px solid var(--colorLightGrey);
						}

						.info {
							margin-top: 120px;
						}

						.agency-border {
							display: none;
						}
					}

					.more-info {
						flex-direction: column;

						.agent-block {
							position: relative;

							.desc {
								width: 95%;
							}

							.details {
								// position: absolute;
								left: 195px;
								bottom: 35px;
							}
						}

						.map-block {
							.agency-map {
								width: 100%;
							}
						}
					}
				}
			}
		}
	}

	@media (max-width: 320px) {
		flex-direction: column;
		// padding-top: 40px;
		margin-left: 0px;

		.Dashboard__container .properties-block .properties-list .property-main-block .property-block__info .address {
			margin-top: -28px;
		}

		.Dashboard__container .properties-block .properties-list .property-main-block .property-block__info .short-desc .time {
			margin-bottom: 14px;
			-moz-box-orient: horizontal;
			-moz-box-direction: normal;
			flex-direction: column;
		}

		.find-agent-block {
			.agency-block {
				.short-info {
					flex-direction: column;
					align-items: baseline !important;

					.action-btn {
						top: 20px;
					}

					&__left {
						align-items: flex-start !important;
						margin-left: 10px;
					}

					&__right {
						margin: 12px 0 20px 10px;
					}

					.logo-block {
						// height: 126px;
						position: absolute;
						left: 0;
						width: 100%;
						border: 1px solid var(--colorLightGrey);
					}

					.info {
						margin-top: 20px;
					}

					.agency-border {
						display: none;
					}
				}

				.more-info {
					flex-direction: column;

					.agent-block {
						position: relative;

						.desc {
							width: 95%;
						}

						.details {
							// position: absolute;
							left: 10px;
							bottom: 35px;
						}
					}

					.map-block {
						.agency-map {
							width: 100%;
						}
					}
				}
			}
		}

		.property-main-block {
			width: 100%;
		}

		.Dashboard__container .find-agent-block .agency-block .short-info .action-btn {
			top: 100px;
		}

		.Dashboard .Dashboard__container .properties-block .properties-list .property-main-block {
			margin-top: 0px;
		}

		.Dashboard__container .properties-block .properties-list .property-main-block {
			margin-top: 40px;
		}

		.Dashboard__container .main-info-block .bottom-block .image-block {
			margin-right: 10px;
		}

		.Dashboard__container .find-agent-block .agency-block .short-info .logo-block {
			position: absolute;
			left: 0;
			width: 100%;
			border: 1px solid var(--colorLightGrey);
		}

		.Dashboard__container .find-agent-block .agency-block .short-info .info {
			margin-top: 120px;
		}

		.Dashboard__container .find-agent-block .agency-block .short-info__left {
			-moz-box-align: start !important;
			align-items: flex-start !important;
			margin-left: 0px;
		}
	}

	@media (max-width: 768px) {
		flex-direction: column;
		// padding-top: 40px;
		// margin-left: 6px;

		.property-main-block {
			width: 100%;
		}

		.Dashboard__container .properties-block .properties-list .property-main-block {
			margin-top: 50px;
		}

		.Dashboard__container .main-info-block .bottom-block .image-block {
			margin-right: 10px;
		}
	}

	// @media (max-width: 320px) {
	//   flex-direction: column;
	//   padding-top: 40px;

	//   .property-main-block {
	//     width: 350px;
	//   }
	//   .Dashboard__container
	//     .properties-block
	//     .properties-list
	//     .property-main-block {
	//     margin-top: 0px;
	//   }

	//   .find-agent-block {
	//     .agency-block {
	//       min-height: 170px;

	//       .short-info {
	//         flex-direction: column;
	//         align-items: baseline !important;

	//         .action-btn {
	//           top: 20px;
	//         }

	//         &__left {
	//           align-items: flex-start !important;
	//           margin-left: 165px;
	//         }

	//         &__right {
	//           margin: 12px 0 20px 145px;
	//         }

	//         .logo-block {
	//           // height: 126px;
	//           position: absolute;
	//           left: 0;
	//           width: 140px;
	//           border: 1px solid var(--colorLightGrey);
	//         }

	//         .info {
	//           margin-top: 20px;
	//         }

	//         .agency-border {
	//           display: none;
	//         }
	//       }

	//       .more-info {
	//         flex-direction: column;

	//         .agent-block {
	//           position: relative;

	//           .desc {
	//             width: 95%;
	//           }

	//           .details {
	//             // position: absolute;
	//             left: 195px;
	//             bottom: 35px;
	//           }
	//         }

	//         .map-block {
	//           .agency-map {
	//             width: 100%;
	//           }

	//         }
	//       }
	//     }
	//   }
	// }

	@media (max-width: 768px) {
		.image-block {
			margin-right: 30x;
		}

		.find-agent-block {
			padding: 20px;

			background: var(--colorWhite);
			border-radius: 10px;

			.load-more {
				margin: 30px auto 0;
				width: 224px;
			}

			h3 {
				font-size: 20px;
			}
		}

		.btn-group,
		.btn-group-vertical {
			position: relative;
			display: flex;
			/* vertical-align: middle; */
			flex-direction: row;
			flex-wrap: wrap;
		}

		.Dashboard__container .properties-block .properties-list {
			width: 100%;
		}
	}

	@media (max-width: 575.98px) {}
}