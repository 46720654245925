.ring {
	font-family: var(--fontNunitoBold);
	font-weight: 700;
	font-size: 24px;
	line-height: 32px;
}
.friendly-p {
	font-family: var(--fontNunitoSans);
	height: 45;
	font-weight: 400;
	font-size: 14px;
	line-height: 21px;
	color: #1d2e5b;
}
.contact-p {
	font-family: var(--fontNunitoSans);
	font-weight: 700;
	font-size: 24px;
	line-height: 32.74px;
}
.lines {
	font-family: var(--fontNunitoSans);
	font-weight: 400;
	font-size: 12px;
}
.custom_btn {
	font-family: var(--fontNunitoSans);
	gap: 20px;
	flex: none;
	order: 0;
	flex-grow: 0;
	width: 240px;
	height: 50px;
	background: #3871ef;
	border-radius: 8px;
}
.font-p {
	font-family: var(--fontNunitoBold);
}
.info-top {
	margin-top: 47px;
}
.info-bottom {
	padding-bottom: 47px;
}
.bg-ring {
	background: #f2f6ff;
}
